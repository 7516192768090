<template>
  <div class="d-flex flex-row justify-content-end">
    <div v-if="!waitingReply">
      <div class="text-bubble">{{ text }}</div>
    </div>
    <div v-else>
      <div class="text-bubble">
        <div class="typing">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
  props: {
    text: String,
    waitingReply: Boolean,
  },
};
</script>

<style scoped>
.panoptic-icon {
  width: 60px;
  height: 60px;
  margin-right: 28px;
  margin-top: 10px;
}
.text-bubble {
  max-width: 510px;
  font-size: 20px;
  padding: 20px 25px;
  border-radius: 40px;
  background: linear-gradient(92deg, #007995 29.22%, #5affeb 102.03%);
  animation: bubble-in 1s ease 1 forwards;
  margin-bottom: 27px;
  opacity: 0;
  color: white;
}

@media (min-width: 1080px) {
  .text-bubble {
    padding: 20px 25px;
  }
}

@media (min-width: 1920px) {
  .text-bubble {
    font-size: 28px;
  }
}

@keyframes bubble-in {
  0% {
    transform: translateY(20px);
  }
  100% {
    opacity: 100;
  }
}
</style>

<style lang="scss" scoped>
$dot-width: 10px;
$dot-color: #fff;
$speed: 1.5s;

.typing {
  position: relative;
  width: 40px;
  height: 10px;
  span {
    content: "";
    animation: blink $speed infinite;
    animation-fill-mode: both;
    height: $dot-width;
    width: $dot-width;
    background: $dot-color;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;

    &:nth-child(2) {
      animation-delay: 0.2s;
      margin-left: $dot-width * 1.5;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
      margin-left: $dot-width * 3;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
</style>