<template>
  <div>
    <LoadingScreen
      v-if="
        !Object.keys(this.$root.$data.doneLoading).every((key) => {
          return this.$root.$data.doneLoading[key];
        })
      "
    />

    <!-- button navigation -->
    <div class="buttons">
      <locale-button id="locale-button" class="icon" />

      <div
        class="btn navbar-btn mx-2 my-4"
        data-bs-toggle="modal"
        data-bs-target="#quickTutorialModal"
      >
        <img class="quick-help-button" src="./assets/help_icon.svg" />
      </div>

      <button
        class="btn btn-success navbar-btn mx-2 my-4 mx-lg-0 button-rounded"
        id="power-on-button"
        v-show="!starting"
        :disabled="starting"
        @click="startSystem()"
      >
        <span
          v-show="starting"
          class="spinner-border spinner-border-sm button-spinner2"
          role="status"
          aria-hidden="true"
        ></span>
        {{ t("button.power_on") }}
      </button>
      <button
        class="btn btn-danger navbar-btn mx-2 my-4 mx-lg-0 button-rounded"
        v-show="starting"
        :disabled="!starting || vsStatus == 'measuring'"
        v-on:click="stopSystem()"
      >
        <span
          v-show="!starting"
          class="spinner-border spinner-border-sm button-spinner2"
          role="status"
          aria-hidden="true"
        ></span>
        {{ t("button.shutdown") }}
      </button>
      <!-- <button
        class="btn btn-light navbar-btn mx-2 my-4 full-screen"
        v-on:click="fullScreen()"
      >
        {{ t("button.full_screen") }}
      </button> -->
    </div>
    <!-- </nav> -->

    <!-- popup tutorial page -->
    <scan-tutorial />

    <!-- popup user profile -->
    <user-profile-input
      v-if="form"
      @updateProfile="updateProfile"
      :profileFilled="profileFilled"
      :form="form"
    />

    <!-- video container -->
    <div id="video-container" ref="videoContainer">
      <!-- condition checking & instructions -->
      <div>
        <information-box :enable="vsStatus == 'measuring'" />
        <scanning-text-popup
          v-if="false"
          :timeLeft="timeLeft"
          class="instruction-box"
        />
        <face-mesh-view
          v-if="detectionResult !== undefined && starting"
          :result="detectionResult"
        />
        <VitalSignCamera
          ref="vscam"
          class="video"
          :isActive="starting"
          :device="selectedDevice"
          :userInfo="userInfo"
          :config="config"
          @onVideoFrameProcessed="onVideoFrameProcessed"
          @onInitialized="onInitialized"
          @onError="onError"
          @onCameraDevicesUpdated="enumerateDevice"
        />
        <!-- <face-detection-view
          ref="face_detection"
          :enable="starting"
          :vsStatus="vsStatus"
          @finishMeasurement="finishMeasure"
          @updateTimeLeft="updateTimeLeft"
          @detectionResult="updateDetectionResult"
          @scanParameters="updateScanParameters"
          :device="devices.length > 0 ? devices[cameraIndex].id : ''"
          :failedScan="failedScan"
          :online="online"
        /> -->
      </div>

      <!-- <information-box
        :enable="vsStatus == 'measuring' && startMeasureTime !== null"
      ></information-box> -->
      <div
        v-if="vsStatus == 'conditionChecking' && startCountdown"
        class="instruction-box"
      >
        <!-- Scanning starting in {{ countdown }}, stay still. -->
        {{ t("instruction.count_down1") }}{{ countdown + 1
        }}{{ t("instruction.count_down2") }}
      </div>

      <img id="logo" src="./assets/panoptic_ai.svg" />
      <!-- signal quality box -->

      <div v-if="failedScan" class="instruction-text">
        <p class="w-75">
          Face Lost!
          <br />
          Please keep your face visible to the camera
        </p>
      </div>
      <div class="huds-container" v-if="vitalSign">
        <div class="row gx-0 mb-5 justify-content-between mx-5">
          <signal-quality-box :scanParameters="scanParameters" />
          <wellness
            :data="vitalSign.generalWellness"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
          />
        </div>
        <!-- <div class="huds-container" v-if="starting && vitalSign"> -->
        <div class="row gx-0 mb-5 justify-content-between mx-5">
          <heart-rate :data="vitalSign.heartRate" class="col-2" />
          <facial-skin-age :data="vitalSign.facialSkinAge" class="col-2" />
          <!-- <hrv-ibi :data="vitalSign.ibi" class="col-2" /> -->
        </div>
        <div class="row gx-0 my-5 justify-content-between mx-5">
          <!-- <hrv-ibi :data="vitalSign.ibi" class="col-2" /> -->
          <o2-saturation :data="vitalSign.spo2" class="col-2" />
          <respiratory-rate
            :data="vitalSign.respiratoryRate"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
          />
        </div>
        <div class="row gx-0 my-5 justify-content-between mx-5">
          <hrv-sdnn
            :data="vitalSign.hrvSdnn"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
          />
          <stress-rating
            :data="vitalSign.stress"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
          />
        </div>
        <div class="row gx-0 my-5 justify-content-between mx-5">
          <blood-pressure
            :data="vitalSign.bloodPressure"
            :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
          />
        </div>
      </div>

      <!-- conditions -->
      <div v-if="vsStatus == 'conditionChecking' && conditions">
        <conditions-hud :conditions="conditions" />
      </div>
      <!-- Vital Sign Button Row -->
      <div class="info-bar align-items-center" v-if="starting">
        <div class="d-flex flex-column w-30 user-info-buttons">
          <button
            class="btn btn-light info-button"
            type="button"
            @click="showHealthReport"
            :disabled="!profileFilled || vsStatus == 'measuring' || !vitalSign"
          >
            {{ t("button.show_health_report") }}
          </button>
        </div>
        <div class="">
          <vital-sign-button
            class="vsButton"
            :radius="64"
            :enable="detectionResult"
            :timeLeft="timeLeft"
            :vsStatus="vsStatus"
            @vsMeasure:start="startMeasurement"
            @vsMeasure:stop="finishMeasure"
            @removeProfile="removeProfile"
          />
        </div>
        <div class="w-30 camera-selection">
          <div
            class="dropup"
            v-if="devices && devices.length > 1 && vsStatus == 'idle'"
          >
            <button
              class="camera-button"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="./assets/switch-camera.svg" alt="" />
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuButton1"
              role="menu"
            >
              <li v-for="(device, index) in devices" :key="index">
                <div
                  :class="`dropdown-item ${
                    cameraIndex == index ? 'selected-camera' : ''
                  }`"
                  @click="() => switchCamera(index)"
                >
                  {{ device.label }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="copyright text-center">
        <p>{{ t("copyright") }}</p>
      </div>
    </div>

    <side-bar />
  </div>
</template>

<script>
import Logger from "../../common/logger.js";
import { ref, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import adapter from "webrtc-adapter";
import LoadingScreen from "./loading-screen.vue";
import ConditionsHud from "./conditions-hud.vue";
// import FaceDetectionView from "./face-detection-view.vue";
import SideBar from "../sidebar/sidebar.vue";
import ScanTutorial from "./scan-tutorial.vue";
import SignalQualityBox from "./signal-quality-box-floating.vue";
import userProfileInput from "./user-profile-input-lite.vue";
import { newUserManager } from "../../user/company-user-manager.ts";
import VitalSignButton from "./vital-sign-button-js.vue";
// vital signs
import HeartRate from "./vital-signs/heart-rate.vue";
import RespiratoryRate from "./vital-signs/respiratory-rate.vue";
import O2Saturation from "./vital-signs/o2-saturation.vue";
import StressRating from "./vital-signs/stress-rating.vue";
import HrvSdnn from "./vital-signs/hrv-sdnn.vue";
import Wellness from "./vital-signs/wellness.vue";
import BloodPressure from "./vital-signs/blood-pressure.vue";
import LocaleButton from "../sidebar/locale-button.vue";
import { VitalSignCamera, ServerId } from "vue-vital-sign-camera";
// import InformationBox from "./information-box.vue";
import FaceMeshView from "./face-mesh-view.vue";
import ScanningTextPopup from "./scanning-text-popup.vue";
import InformationBox from "./information-box.vue";
import FacialSkinAge from "./vital-signs/facial-skin-age.vue";
export default {
  name: "App",
  props: {
    simulation: Boolean,
  },
  setup() {
    const { idle, updateIdle } = inject("idle");
    const { profileFilled, updateProfileFilled } = inject("profileFilled");
    const { t, locale } = useI18n();
    const router = useRouter();

    var currentUser = newUserManager.currentUser
      ? newUserManager.currentUser.currentUser
      : null;
    let vitalSign = null;
    let scanParameters = null;
    if (currentUser) {
      vitalSign = currentUser.lastVitalSigns;
      scanParameters = currentUser.lastHealth
        ? currentUser.lastHealth.scanParameters
        : null;
    } else {
      router.push("/");
    }

    return {
      t,
      locale,
      router,
      starting: ref(idle.value),
      vsStatus: ref("idle"),
      vitalSign: ref(vitalSign),
      scanParameters: ref(scanParameters),
      conditions: ref(),
      disclaimer: ref(),
      timeLeft: ref(0),
      user: ref(currentUser),
      idle: idle.value,
      updateIdle,
      showTutorial: ref(false),
      profileFilled,
      updateProfileFilled,
    };
  },
  mounted() {
    // console.log(newUserManager.currentUser)
    // console.log(newUserManager.currentUser.currentUser)
    // console.log(newUserManager.currentUser.currentUser.getUserSettings())
    // load user / customer form
    this.online = window.navigator.onLine;
    window.addEventListener("online", () => (this.online = true));
    window.addEventListener("offline", () => (this.online = false));

    setTimeout(async () => {
      let currentUser = newUserManager.currentUser.currentUser;
      if (currentUser) {
        this.form = await currentUser.getUserSettings();
      }
    }, 50);
  },
  beforeUnmount() {
    window.removeEventListener("online", () => (this.online = true));
    window.removeEventListener("offline", () => (this.online = false));
  },
  async created() {
    await this.getUserType();
    if (window.sessionStorage.userSettings) {
      this.form = JSON.parse(window.sessionStorage.userSettings);
    }
    this.$root.$data.doneLoading.faceDetectionView = false;
  },
  watch: {
    cameraIndex(newVal) {
      this.selectedDevice = this.devices[newVal].id;
    },
    healthResult() {
      if (this.healthResult.stage == 3 && this.startMeasureTime) {
        if (this.healthResult.health) {
          const health = {};
          this.$root.$data.doneLoading.scanning = true;
          Object.assign(health, this.healthResult.health);
          health.vitalSigns.facialSkinAge = Math.max(
            18,
            health.vitalSigns.facialSkinAge
          );
          newUserManager.currentUser.currentUser.setHealth(health);
          newUserManager.currentUser.uploadScanResults();
          this.$root.$data.healthObject = health;
          this.scanParameters = health.scanParameters;
          this.finishMeasure();
        }
      } else if (
        this.healthResult.stage !== 3 &&
        this.startMeasureTime &&
        this.timeLeft <= 0 &&
        this.$root.$data.doneLoading.scanning
      ) {
        this.$root.$data.doneLoading.scanning = false;
      }
    },
    conditions() {
      if (!this.conditions) {
        return;
      }
      let okToScan;
      okToScan = Object.values(this.conditions).every((item) => item);
      if (this.vsStatus == "conditionChecking") {
        if (okToScan && !this.interval) {
          this.interval = setInterval(() => {
            this.startCountdown = true;
            this.countdown--;
            if (this.countdown < 0) {
              this.$refs.vscam.startScanning();
              this.vsStatus = "measuring";
              clearInterval(this.interval);
              this.startMeasureTime = new Date();
              this.interval = undefined;
              this.countdown = 3;
              this.startCountdown = false;
            }
          }, 1000);
        }
        if (!okToScan && this.interval) {
          clearInterval(this.interval);
          this.interval = undefined;
          this.countdown = 3;
          this.startCountdown = false;
        }
      }
    },
    detectionResult(newValue) {
      if (!newValue) {
        this.vsStatus = "idle";
      }
    },
    failedScan(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.failedScan = false;
        }, 5000);
      }
    },
    "$root.$data.healthObject": {
      deep: true,
      handler: function () {
        this.vitalSign = newUserManager.currentUser.currentUser.lastVitalSigns;
      },
    },
    starting() {
      if (this.starting) {
        this.enumerateDevice();
      }
    },
  },
  data() {
    return {
      scanTime: 30,
      detectionResult: undefined,
      aspectRatio: undefined,
      form: undefined,
      userType: undefined,
      cameraIndex: 0,
      devices: [{ text: "", id: "", deviceIndex: 0 }],
      checkDevice: undefined,
      failedScan: false,
      online: true,
      countdown: 3,
      interval: undefined,
      config: {
        serverId: ServerId.AwsProdEnterprise,
        apiKey: "yOxH1s1vRK71SrjujQvrl5iyzuC9VxqX5NTSChBG",
      },
      startCountdown: false,
      startScanning: false,
      startMeasureTime: undefined,
      userInfo: {},
      healthResult: undefined,
      // customConditionRange: {
      //         distanceRange: [0.1, 0.9],
      //         boundaryRangeX: [0.3, 0.7],
      //         boundaryRangeY: [0.2, 0.8],
      //       },
      selectedDevice: "",
    };
  },
  async beforeRouteLeave() {
    if (this.starting) {
      this.starting = false;
      await this.$refs.vscam.vscam.waitCameraToStop();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.idle) {
        vm.starting = true;
      }
    });
  },
  methods: {
    // onVideoSettingsUpdated(event) {
    //       this.$refs.vscam.vscam.faceDetectionRegion = {
    //         x: event.settings.width / 3,
    //         y: 0,
    //         width: event.settings.width / 3,
    //         height: event.settings.height,
    //       };
    //     },
    onError(error) {
      this.error = error;
      this.faceLost = error.message.includes("face lost");
      if (!this.faceLost) {
        throw error;
      }
    },
    updateConfig() {
      const currentUser = newUserManager.currentUser.currentUser;

      var userSettings = currentUser.userSettings;
      var companySettings = newUserManager.currentUser.companySettings;
      if (companySettings === undefined) {
        return;
      }
      Object.assign(this.userInfo, userSettings);
      this.userInfo.userId = companySettings.UID;
      this.userInfo.planType = companySettings.planType;
      this.userInfo.planExpiryDate = companySettings.planExpiryDate;
      delete this.userInfo.name;
      delete this.userInfo.email;
      delete this.userInfo.phoneNumber;
      delete this.userInfo.profileImage;
    },
    onVideoFrameProcessed(processedVideo) {
      const { facebox, healthResult, scanConditions, landmarks, videoFrame } =
        processedVideo;
      if (facebox && videoFrame && landmarks) {
        this.detectionResult = {
          facebox,
          landmarks,
          videoFrame,
        };
      } else {
        this.detectionResult = undefined;
      }
      this.conditions = scanConditions;
      this.healthResult = healthResult;
      if (this.startMeasureTime && this.timeLeft >= 0) {
        this.timeLeft =
          this.scanTime - (new Date() - new Date(this.startMeasureTime)) / 1000;
      }
    },
    onInitialized() {
      this.$root.$data.doneLoading.faceDetectionView = true;
    },
    enumerateDevice(event) {
      if (!event || !event.devices || !this.starting) {
        return;
      }

      this.devices = event.devices;
      // this.checkDevice = setInterval(async () => {
      // const preferredCameraId =
      //   "096f27d62db1fcc45bd319665cc698478ec3769f6e90a40666012144dc5634e2";
      // let devices = event.devices;
      // const preferredCamera = devices.find(
      //   (item) => item.id == preferredCameraId
      // );
      // var tempDevices = [];
      // if (preferredCamera) {
      //   tempDevices = [
      //     {
      //       label: preferredCamera.label,
      //       id: preferredCamera.id,
      //       deviceIndex: 0,
      //     },
      //   ];
      // }
      // devices.map((device, index) => {
      //   if (
      //     device.label !== "screen-capture-recorder" &&
      //     tempDevices.filter((item) => item.label == device.label).length == 0
      //   ) {
      //     tempDevices.push({
      //       label: device.label,
      //       id: device.id,
      //       deviceIndex: index,
      //     });
      //   }
      // });
      // this.devices = tempDevices;
      // this.selectedDevice = this.devices[this.cameraIndex].id;

      // }, 1000);
    },
    switchCamera(index) {
      this.cameraIndex = index;
    },
    async getUserType() {
      setTimeout(() => {
        if (!this.currentUser) return;
        else {
          this.userType = this.currentUser.userSettings.planType;
        }
      }, 2000);
    },
    showGuide() {
      this.showTutorial = !this.showTutorial;
    },
    startMeasurement() {
      // check conditions
      this.vitalSign = null;
      this.scanParameters = null;
      if (newUserManager.currentUser) {
        let currentUser = newUserManager.currentUser.currentUser;
        if (currentUser) {
          currentUser.clearHealth();
        }
      }
      this.vsStatus = "conditionChecking";
      this.failedScan = false;
    },
    finishMeasure(failedScan) {
      this.vsStatus = "idle";
      this.failedScan = failedScan;
      this.startMeasureTime = undefined;
      this.timeLeft = 0;
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = undefined;
        this.countdown = 3;
        this.startCountdown = false;
      }
    },
    updateScanParameters(newValue) {
      this.scanParameters = newValue;
    },
    getAspectRatio(ar) {
      this.aspectRatio = ar;
    },
    onResult(result) {
      this.detectionResult = result;
    },
    startSystem: function () {
      Logger.info("Start button pressed");
      this.enumerateDevice();
      // this.enableSound();

      this.updateIdle();
      this.starting = true;
      this.conditions = undefined;
      this.vitalSign = undefined;
    },
    stopSystem: function () {
      Logger.info("Stop button pressed.");

      this.updateIdle();
      this.starting = false;
      this.vitalSign = undefined;
      this.conditions = undefined;
      this.detectionResult = undefined;
      this.vsStatus = "idle";
      this.failedScan = false;
    },
    fullScreen() {
      this.$refs.videoContainer.requestFullscreen();
    },
    showHealthReport() {
      this.$router.push("/email");
    },
    async removeProfile() {
      await newUserManager.currentUser.currentUser.clearUserSettings();
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(false);
      this.vitalSign = null;
      this.scanParameters = null;
      this.failedScan = false;
    },
    async updateProfile(form) {
      await newUserManager.currentUser.currentUser.setUserSettings(form);
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(true);
      Logger.log("updateProfile", "", this.form);
      this.updateConfig();
    },
  },
  computed: {
    healthObject() {
      if (this.$root.$data.healthObject !== undefined) {
        return "healthObject updated.";
      } else {
        return "no health result";
      }
    },
    gap() {
      Logger.log(
        `%ccurrent Browswer: [${adapter.browserDetails.browser}]`,
        "color: #DDDDDD"
      );
      if (adapter.browserDetails.browser == "safari") {
        return "0px";
      } else {
        return 0;
      }
    },
  },
  components: {
    InformationBox,
    ScanningTextPopup,
    FaceMeshView,
    // VideoFeed,
    // InformationBox,
    VitalSignCamera,
    LocaleButton,
    VitalSignButton,
    HeartRate,
    RespiratoryRate,
    O2Saturation,
    StressRating,
    // HrvIbi,
    HrvSdnn,
    BloodPressure,
    //CVDRisk,
    //CovidRisk,
    Wellness,
    ConditionsHud,
    SideBar,
    //WebCam,
    ScanTutorial,
    SignalQualityBox,
    userProfileInput,
    LoadingScreen,
    FacialSkinAge,
  },
};
</script>

<style>
body {
  background-color: lightgray;
  /* overflow: hidden; */
  /* transform: scale(0.8); */
}
</style>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.quick-help-button {
  /* display: inline-block; */
  opacity: 0.7;
  min-width: 40px;
  min-height: 40px;
  vertical-align: middle;
  z-index: 2;
}
.buttons {
  display: flex;
  position: absolute;
  vertical-align: middle;
  align-items: center;
  top: 0px;
  right: 13px;
  z-index: 1;
}
.video {
  position: absolute;
  left: 87px;
  top: 0;
  width: calc(100% - 87px);
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

#logo {
  position: absolute;
  width: 300px;
  height: 60px;
  left: 107px;
  top: 13px;
}
.instruction-box {
  position: absolute;
  top: 10%;
  width: calc(100% - 87px);
  font-size: 40px;
  text-align: center;
  color: #ff3535;
  text-shadow: 1px 1px white;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
#video-container {
  height: 100vh;
  overflow: hidden !important;
  margin-left: 87px;
  z-index: 0;
}
.disclaimer {
  position: absolute;
  left: 40%;
  right: 40%;
  top: 20px;
  padding: 10px;
  color: black;
  background-color: lightgrey;
  opacity: 0.8;
  font-size: 0.9em;
  text-align: center;
}
.vsButton {
  overflow: hidden;
}
.button-rounded {
  border-radius: 50px;
  min-width: 120px;
}
.copyright {
  position: relative;
  font-size: 14px;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, 0);
}
#icon-mobile {
  display: none;
}
.huds-container {
  position: absolute;
  top: 15%;
  width: calc(100% - 87px);
  height: 80%;
  overflow-y: scroll;
  /* height: fit-content; */
}
.huds-container::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.huds-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.disableBox {
  pointer-events: none;
  opacity: 0.7;
}
.info-bar {
  position: absolute;
  left: calc(87px + 22.5%);
  width: calc(55% - 87px);
  bottom: 6%;
  display: flex;
  justify-content: space-evenly;
}
.info-button {
  font-size: 20px;
  padding: 10px;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.w-30 {
  text-align: center;
  width: 30%;
}
.user-info-buttons {
  row-gap: 20px;
}
.camera-selection {
  z-index: 4;
}
.camera-button {
  border: none;
  background: transparent;
}
.selected-camera {
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 500;
}
.instruction-text {
  position: absolute;
  display: flex;
  justify-content: center;
  width: calc(100% - 87px);
  top: 12%;
  font-size: 30px;
  color: white;
  text-shadow: 0px 0px 5px black;
  z-index: 3;
  text-align: center;
  pointer-events: none;
}

@media (max-width: 1044px) {
  .huds-container {
    position: absolute;
    top: auto;
    left: 87px;
    bottom: 200px;
    width: calc(100% - 87px);
    height: 15%;
    overflow-y: scroll;
    overflow-x: scroll;
    display: flex;
    /* justify-content: center; */
    gap: v-bind(gap);
  }
  .row {
    justify-content: flex-start !important;
    flex-wrap: nowrap;
    margin: auto 2px !important;
  }
  .info-bar {
    position: absolute;
    left: 87px;
    width: calc(100% - 87px);
    bottom: 6%;
    display: flex;
    justify-content: space-evenly;
  }
}
@media (max-width: 768px) {
  #video-container {
    margin-left: 0px;
  }
  #logo {
    position: absolute;
    width: 200px;
    height: 30px;
    left: 20px;
    top: 25px;
  }
  #icon-mobile {
    display: inline;
    position: absolute;
    width: 35px;
    height: auto;
    left: 50vw;
    top: 4vh;
  }
  button {
    display: block;
    z-index: 1;
    font-size: 12px;
  }
  .buttons {
    min-width: 150px;
  }
  .full-screen {
    display: none;
  }
  .huds-container {
    width: 100%;
    left: 0;
  }
  .instruction-box {
    width: 100%;
  }
  .info-bar {
    left: 0px;
    width: 100%;
    bottom: 8%;
  }
  /* .info-button {
    width: 150px;
    height: 75px;
    min-height: 50px;
    font-size: large;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    margin-top: auto;
    margin-bottom: auto;
  } */
}

@media (max-width: 700px) {
  .huds-container {
    justify-content: flex-start;
    height: 20%;
    bottom: 170px;
    /* height: fit-content; */
  }
  .instruction-box {
    width: 100%;
  }
  .info-bar {
    left: 0px;
    width: 100%;
    bottom: 11%;
  }
  .info-button {
    width: 100px;
    height: 70px;
    min-height: 50px;
    font-size: small;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    margin-top: auto;
    margin-bottom: auto;
  }
}
</style>
