export declare interface NormalizedLandmark {
  x: number;
  y: number;
  z?: number;
  visibility?: number;
}
export type NormalizedLandmarkList = NormalizedLandmark[];
export type LandmarkConnectionArray = Array<[number, number]>;
export declare interface DrawingOptions {
  color?: string | CanvasGradient | CanvasPattern;
  fillColor?: string | CanvasGradient | CanvasPattern;
  lineWidth?: number;
  radius?: number;
  visibilityMin?: number;
}

export function drawConnectors(
  ctx: CanvasRenderingContext2D,
  landmarks?: NormalizedLandmarkList,
  connections?: LandmarkConnectionArray,
  options?: DrawingOptions
): void {
  if (landmarks === undefined) {
    return;
  }

  if (connections === undefined) {
    return;
  }

  ctx.save();
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  for (var i = 0; i < connections.length; i++) {
    const connection = connections[i];
    ctx.beginPath();
    const from = landmarks[connection[0]];
    const to = landmarks[connection[1]];
    ctx.moveTo(from.x * width, from.y * height);
    ctx.lineTo(to.x * width, to.y * height);
    ctx.lineWidth = options?.lineWidth || 1.0;
    ctx.strokeStyle = options?.color || "lightgreen";
    ctx.stroke();
  }

  ctx.restore();
}
