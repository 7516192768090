<template>
  <div
    class="modal fade"
    id="userProfileInput"
    tabindex="-1"
    aria-hidden="true"
    ref="userProfileModal"
  >
    <div class="modal-dialog modal-fullscreen">
      <terms-condition
        v-if="!agreeTnc"
        @updateAgreeTnc="updateAgreeTnc"
        @closeModal="closeModal"
        :showModal="showModal"
      />
      <profile-form
        v-else
        @updateProfile="updateProfile"
        @closeModal="closeModal"
        :form="form"
      />
    </div>
  </div>
</template>
  
<script>
import { inject } from "vue";
import { useI18n } from "vue-i18n";
import TermsCondition from "./terms-condition.vue";
import ProfileForm from "./profile-form.vue";
export default {
  name: "user-profile-input",
  props: {
    form: Object,
  },
  components: {
    TermsCondition,
    ProfileForm,
  },
  setup() {
    const { t, locale } = useI18n();
    const { profileFilled, updateProfileFilled } = inject("profileFilled");
    return {
      profileFilled,
      updateProfileFilled,
      t,
      locale,
    };
  },
  mounted() {
    const myModal = this.$refs.userProfileModal;
    myModal.addEventListener("shown.bs.modal", () =>
      this.updateModalShownStatus()
    );
  },
  unmounted() {
    const myModal = this.$refs.userProfileModal;
  },
  data() {
    return {
      agreeTnc: false,
      showModal: false,
    };
  },
  watch: {
    profileFilled() {
      if (!this.profileFilled) {
        this.agreeTnc = false;
      }
    },
  },
  emits: ["updateProfile"],
  methods: {
    updateModalShownStatus() {
      this.showModal = true;
    },
    updateProfile(info) {
      this.$emit("updateProfile", info);
      // window.localStorage.userProfile = JSON.stringify(this.form)
    },
    updateAgreeTnc(agree) {
      this.agreeTnc = agree;
    },
    closeModal() {
      this.agreeTnc = false;
      this.showModal = false;
    },
  },
};
</script>

  
<style scoped>
.modal-dialog {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .down-arrow {
  height: 50px;
  width: 50px;
}
/deep/ .down-arrow-container {
  bottom: 0%;
  position: sticky;
  display: flex;
  width: 100%;
  justify-content: center;
}
/deep/ .button-container {
  margin: 0 30px;
  margin-bottom: 54px;
}
/deep/ .chat-container {
  margin: 30px;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
/deep/ .chat-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
/deep/ .text-bubble:disabled {
  background: grey !important;
  opacity: 0.3;
}
/deep/ .end-button {
  border-radius: 36.952px;
  border: 2.771px solid #007995;
  color: #007995;
  font-size: 24px;
  padding: 13px 32px;
}
/deep/ .information-button {
  position: absolute;
  right: 0%;
  padding: 0%;
  margin-right: 30px;
}
/deep/ .modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  /* min-height: 95%; */
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  border-radius: 26px;
}
/deep/ .modal-header {
  padding: 0;
}
/deep/ .modal-subtitle {
  color: white;
  font-size: 25px;
  font-size: 500;
  margin-left: 36px;
}
/deep/ .modal-title {
  color: #1ba2af;
  background-color: white;
  padding: 5px 20px;
  border-radius: 16px;
  height: 100%;
}
/deep/ .text-bubble {
  font-size: 24px;
  border-radius: 40px;
  background: linear-gradient(92deg, #007995 29.22%, #5affeb 102.03%);
  color: white;
  padding: 13px 32px;
  border: none;
}
/deep/ .title {
  z-index: 1;
  padding: 35px 0;
  height: 150px;
}
/deep/ .tnc-title {
  color: black;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2%;
}
/deep/ .vitals-gpt-background {
  width: auto;
  min-height: 200px;
  background-image: url(./assets/vitals-gpt-background.svg);
  background-position: bottom;
  background-size: cover;
  position: relative;
  z-index: 0;
}
/deep/ .vitals-logo {
  height: inherit;
}

@media (min-width: 1080px) {
  /deep/ .vitals-gpt-background {
    min-height: 125px;
  }
  /deep/ .title {
    height: 90px;
    padding: 20px 0;
  }
  /deep/ .chat-container {
    margin: 0 100px 30px 100px;
  }
  /deep/ .button-container {
    margin-bottom: 40px;
  }
}

@media (min-width: 1920px) {
  /deep/ .vitals-gpt-background {
    min-height: 225px;
  }
  /deep/ .chat-container {
    margin: 0 150px 30px 150px;
  }
  /deep/ .title {
    height: 150px;
    padding: 35px 0;
  }
  /deep/ .end-button {
    padding: 20px 40px;
    font-size: 30px;
  }
  /deep/ .text-bubble {
    padding: 20px 40px;
    font-size: 30px;
  }
}
</style>
  
  
  