<template>
  <div class="modal-content">
    <div>
      <div
        class="d-flex justify-content-between align-items-start position-absolute title w-100"
      >
        <div class="d-flex align-items-center h-100">
          <div class="modal-title">VitalsGPT</div>
          <div class="modal-subtitle">Beta Version</div>
        </div>
        <button
          class="btn btn-clear"
          aria-label="close"
          data-bs-dismiss="modal"
          @click="closeModal"
        >
          <img src="./assets/close-button.svg" alt="" />
        </button>
      </div>
      <div class="vitals-gpt-background" />
    </div>
    <div class="chat-container flex-grow h-100">
      <!-- debugging bubble -->
      <!-- <gpt-bubble :text="'What are some lifestyle changes I could make to improve my heart rate variability, and are there any underlying health issues that could be contributing to this measure?'" :waitingReply="false" />
      <user-bubble :text="'What are some lifestyle changes I could make to improve my heart rate variability, and are there any underlying health issues that could be contributing to this measure?'" />
      <prompts-options
        :prompts="['What is heart rate', 'What is HRVSDNN', 'What is oxygen saturation']"
        @chooseQuestion="chooseQuestion"
      /> -->
      <!--  -->
      <div
        v-for="(chat, index) in chatHistory"
        :key="index"
        :ref="'chat_' + index"
      >
        <gpt-bubble
          v-if="chat.role == 'assistant'"
          :text="chat.content"
          :waitingReply="false"
        />
        <user-bubble v-if="chat.role == 'user'" :text="chat.content" />
      </div>
      <div ref="gpt_typing_bubble">
        <gpt-bubble v-if="waitingGptReply" :text="''" :waitingReply="true" />
      </div>
      <div ref="options_typing_bubble">
        <user-bubble v-if="waitingOptions" :text="''" :waitingReply="true" />
      </div>
      <div ref="prompt_options">
        <prompts-options
          v-if="this.prompt_suggestions.length > 0"
          :prompts="prompt_suggestions"
          @chooseQuestion="chooseQuestion"
        />
      </div>
      <div v-if="chatError" class="chat-error" ref="chat_error">
        <div v-html="t('vitals_gpt.error')"></div>
        <button class="btn btn-clear mt-2 rounded-circle p-3" @click="retry">
          <img src="./assets/retry.svg" alt="" class="retry-button" />
        </button>
      </div>
    </div>
    <div class="disclaimer">
      <i>*{{ t("vitals_gpt.disclaimer") }}</i>
    </div>
    <div
      class="button-container d-flex justify-content-around align-items-center"
    >
      <button
        class="btn end-button"
        data-bs-toggle="modal"
        data-bs-target="#backPrompt"
      >
        {{ t("button.restart_scan") }}
      </button>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import GptBubble from "./gpt-bubble.vue";
import PromptsOptions from "./prompts-options.vue";
import UserBubble from "./user-bubble.vue";
import { newUserManager } from "../../user/company-user-manager.ts";
import { sendChat } from "../../api/vitals-gpt.js";

export default {
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  props: {
    showVitalsGpt: Boolean,
  },
  emits: ["updateShowVitalsGpt"],
  data() {
    return {
      chatRequest: {},
      chatHistory: [],
      prompt_suggestions: [],
      vitalSigns: {},
      waitingGptReply: false,
      waitingOptions: false,
      chatError: false,
    };
  },
  async mounted() {
    const lastVitalSigns =
      newUserManager.currentUser.currentUser.lastVitalSigns;

    this.vitalSigns = {
      generalWellness: lastVitalSigns.generalWellness,
      stress: lastVitalSigns.stress,
      respiratoryRate: lastVitalSigns.respiratoryRate,
      oxygenSaturation: lastVitalSigns.spo2,
      facialSkinAge: lastVitalSigns.facialSkinAge,
      heartRate: lastVitalSigns.heartRate,
      hrvSdnn: lastVitalSigns.hrvSdnn,
      bloodPressure: lastVitalSigns.bloodPressure,
    };

    await this.startChat();
  },
  methods: {
    async retry() {
      this.chatError = false;
      await this.startChat();
    },
    async startChat() {
      this.chatHistory = [];
      this.chatRequest = [
        {
          role: "system",
          content: `Pretend that you are ChatGPT. Your name is VitalsGPT!You are a chatbot in a health scanning app called Vitals. I am a ${this.getAge()} year old female who has scanned my health using the app and got the following result:
          ${JSON.stringify(this.vitalSigns)}
          Stress level score is between 1-5, with 1 being low stress and 5 being high stress level.
          Limit your answer to only 100 words maximum. Refrain from adding any kind of note to your responses. 
        `,
        },
        {
          role: "user",
          content:
            "Please greet me by introducing yourself without mentioning any of my health scan result.",
        },
      ];
      this.waitingGptReply = true;
      sendChat(this.chatRequest)
        .then((greeting) => {
          this.waitingGptReply = false;
          this.chatRequest.push(greeting);
          this.chatHistory.push({
            role: greeting.role,
            content: greeting.content.replace(/\*.*?\*/g, ""),
          });
          this.chatRequest.push({
            role: "user",
            content:
              "Suggest me 3 questions for me to ask you about my health scan result. Each question shouldn't be more than 20 words maximum.",
          });
          setTimeout(() => {
            this.waitingOptions = true;
            sendChat(this.chatRequest)
              .then((response) => {
                this.waitingOptions = false;
                this.extractQuestions(response.content);
                this.chatRequest.push(response);
              })
              .catch((e) => {
                this.chatError = true;
                console.error(e);
              });
          }, 2000);
        })
        .catch((e) => {
          this.chatError = true;
          console.error(e);
        });
    },
    scrollChat(role) {
      const userChat = this.chatHistory.findLastIndex((a) => a.role == role);
      this.$refs["chat_" + userChat][0].scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    extractQuestions(text) {
      const regex = /(?<=\d\.\s).*\?/g;
      const questions = text.match(regex);
      this.prompt_suggestions = questions.slice(0, 3);
      setTimeout(() => {
        if (this.$refs.prompt_options) {
          this.$refs.prompt_options.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      }, 1000);
    },
    getAge() {
      if (!this.currentUser) {
        return "";
      }
      const age = this.currentUser.currentUser.userSettings.age;
      if (age) {
        return age;
      }
      const dateString = this.currentUser.currentUser.userSettings.birthday;
      var ageInMilliseconds = new Date() - new Date(dateString);
      return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
    },
    chooseQuestion(prompt) {
      this.prompt_suggestions = [];
      this.chatHistory.push({
        content: prompt,
        role: "user",
      });
      this.chatRequest.push({
        content: prompt + " (Give very short answer)",
        role: "user",
      });
      setTimeout(() => {
        this.scrollChat("user");
        this.waitingGptReply = true;
        sendChat(this.chatRequest)
          .then((response) => {
            this.waitingGptReply = false;
            this.chatRequest.push(response);
            this.chatHistory.push({
              content: response.content.replace(/\n/g, "<br>"),
              role: response.role,
            });
            setTimeout(() => {
              this.scrollChat("assistant");
              if (this.chatRequest.length < 15) {
                this.chatRequest.push({
                  role: "user",
                  content:
                    "Suggest me 3 questions for me to ask you as a follow up question from your previous answer. Each question shouldn't be more than 20 words maximum.",
                });
                setTimeout(() => {
                  this.waitingOptions = true;
                  sendChat(this.chatRequest)
                    .then((response) => {
                      this.waitingOptions = false;
                      this.extractQuestions(response.content);
                      this.chatRequest.push(response);
                    })
                    .catch((e) => {
                      this.chatError = true;
                      console.error(e);
                    }, 2000);
                });
              } else {
                setTimeout(() => {
                  const farewell = {
                    role: "assistant",
                    content:
                      "Thank you for using Vitals chat service! It was a pleasure assisting you.  Have a great day!",
                  };
                  this.chatRequest.push(farewell);
                  this.chatHistory.push(farewell);
                  setTimeout(() => {
                    this.scrollChat("assistant");
                  }, 1000);
                }, 2000);
              }
            }, 1000);
          })
          .catch((e) => {
            this.chatError = true;
            console.error(e);
          });
      }, 1000);
    },
    closeModal() {
      setTimeout(() => {
        this.$emit("updateShowVitalsGpt", false);
      }, 500);
    },
  },
  watch: {
    waitingGptReply() {
      this.$nextTick(() => {
        if (this.$refs.gpt_typing_bubble) {
          this.$refs.gpt_typing_bubble.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      });
    },
    waitingOptions() {
      this.$nextTick(() => {
        if (this.$refs.options_typing_bubble) {
          this.$refs.options_typing_bubble.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      });
    },
    chatError() {
      this.$nextTick(() => {
        if (this.$refs.chat_error) {
          this.$refs.chat_error.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      });
    },
  },
  components: {
    GptBubble,
    UserBubble,
    PromptsOptions,
  },
};
</script>

<style scoped>
.button-container {
  margin: 0 30px;
  margin-bottom: 54px;
}
.chat-container {
  margin: 45px;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.chat-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.chat-error {
  text-align: center;
  font-size: 18px;
}
.disclaimer {
  margin: 0 45px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}
.end-button {
  border-radius: 40px;
  border: 3px solid #d83131;
  color: #d83131;
  font-size: 20px;
  padding: 13px 32px;
}
.modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  /* min-height: 95%; */
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  border-radius: 26px;
}
.modal-header {
  padding: 0;
}
.modal-subtitle {
  color: white;
  font-size: 25px;
  font-size: 500;
  margin-left: 36px;
}
.modal-title {
  color: #1ba2af;
  background-color: white;
  padding: 11px 44px;
  font-weight: 500;
  border-radius: 16px;
  font-size: 30px;
}
.retry-button {
  width: 35px;
}
.title {
  z-index: 1;
  padding: 45px;
}
.vitals-gpt-background {
  width: 100%;
  min-height: 200px;
  background-image: url(./assets/vitals-gpt-background.svg);
  background-position: bottom;
  background-size: cover;
  position: relative;
  z-index: 0;
}
@media (min-width: 1080px) {
  .vitals-gpt-background {
    min-height: 160px;
  }
  .title {
    height: 100px;
    padding: 25px 45px;
  }
  .chat-container {
    margin: 25px 45px;
  }
  .chat-error {
    font-size: 18px;
  }
  .button-container {
    margin-bottom: 35px;
  }
  .modal-title {
    font-size: 20px;
    padding: 10px 33px;
  }
  .modal-subtitle {
    font-size: 17px;
  }
  .disclaimer {
    font-size: 11px;
  }
}

@media (min-width: 1920px) {
  .vitals-gpt-background {
    min-height: 225px;
  }
  .chat-container {
    margin: 45px 150px 30px 150px;
  }
  .title {
    height: 150px;
    padding: 35px 100px;
  }
}
</style>
