const locales = {
  En: {
    page: {
      dashboard: "Dashboard",
      settings: "Settings",
      help: "Help",
      logout: "Logout",
      coming_soon: "Stay tuned!<br>We are Coming Soon...",
    },
    guest_signin: "Continue as Guest",
    logout_prompt: "Are you sure you want to log out?",
    instruction: {
      stay_still: "Scanning in progress, stay still.",
      count_down1: "Scanning starting in ",
      count_down2: ", stay still",
    },
    button: {
      mode: "Mode",
      fever_percentage: "Fever Percentage",
      fever_threshold: "Threshold Temp.",
      start: "START",
      stop: "STOP",
      shutdown: "SHUT DOWN",
      cancel: "Cancel",
      ok: "OK",
      all: "ALL",
      power_on: "POWER ON",
      full_screen: "FULL SCREEN",
      save_settings: "Save Settings",
      upgrade: "Upgrade",
      change_password: "Change Password",
      continue: "Continue",
      show_health_report: "Show Health Report",
      clear_user_information: "Clear User Info",
      send_to_email: "Send to Email",
      rate_us: "Rate Us!",
      submit: "Submit",
      agree_continue: "Agree & Continue",
      restart_scan: "Restart Health Scan",
      yes: "Yes",
    },
    labels: {
      sending: "Sending Report To Your Email...",
      user_profile: "User Profile",
      rate_us: "Your feedback is valuable to us, please give us a rating!",
      poor: "Poor",
      good: "Good",
      dissatisfied: "Dissatisfied",
      satisfied: "Satisfied",
    },
    mode: {
      normal: "Normal",
      debug: "Debug",
    },
    threshold: {
      auto: "Auto",
    },
    message: {
      startup: 'System is OFF, press "POWER ON" to start.',
      loading: "Loading",
      calibrating: "Stay still, Calibrating ...",
      no_face_box: "No Face Box ...",
      stopping: "Stopping {system_short_name}...",
      confirm_shutdown: "Are you sure you want to shutdown the system?",
      important_message: "Important Message",
      emmisivity:
        '{short_name} v{version} is designed to work with FLIR A315 with emmisivity set to <span class="text-danger font-weight-bold">{emmisivity}</span>.',
      auto_sys_restart: "Automatic System Restart",
      prevent_overheat: "Restarting the system to prevent over-heating...",
      camera_error: "Camera Error Detected",
      system_error: "System Error Detected",
      camera_fatal_error:
        "System automatically stopped due to camera error, please contact {contact}. Thank you.",
      system_fatal_error:
        "System automatically stopped due to error, please contact {contact}. Thank you.",
      system_off: "System is OFF.",
      tap_to_view: "Tap to View",
      condition_check_failed:
        "(1) Please move into the pur ple human <br/>(2) stay still",
      coming_soon: "Coming Soon",
      breath_in: "In",
      breath_out: "Out",
      bad_scan_message: "Bad scan, please restart the scan",
      weak_signal_message: "Weak signal",
      settings_updated: "Settings updated",
      password_updated: "Password updated",
      password_update_failed:
        "Failed to change password, please check your password",
      fill_profile: "Please fill in your company profile",
      conditions: {
        lighting: "Lighting",
        distance: "Distance",
        centered: "Centered",
        movement: "Movement",
        server_ready: "Server Ready",
        tooFar: "Too Far",
        tooClose: "Too Close",
      },
      subscribe: "Subscribe to see this content!",
      scan_quality: "Scan Quality",
      email: "Email sent to",
      feedback: "Feedback received, Thank You!! Have a nice day~",
      camera_preparation:
        "We are downloading some models, please ensure your face is in front of the camera!",
      loading_wait: "Loading, please wait!",
      wait_result: "Analyzing result... Please wait...",
    },
    terms_conditions: {
      title: "Terms & Conditions of the Vitals Scan",
      subtitle:
        "By proceeding with “Vitals Scan”, you confirm that you have read and understand and agree all Terms and Conditions below.",
      content: [
        `"Hong Kong" means the Hong Kong Special Administrative Region of the People's Republic of China.`,

        `“PanopticAI” means PanopticAI Limited.`,

        `“Vitals Scan” is a camera-based, non-contact facial assessment solution provided by PanopticAI that captures images and user-provided data for instant calculation purposes.`,

        `'Customer' means the end-user/ individual using service by Vitals Scan.`,

        `The information, functions, features and services provided by the Vitals Scan are intended as an information service and general guide only and may not cover any or all matters relating to your particular situation. In providing access to Vitals Scan, PanopticAI is not practicing, administering or maintaining a practice as a clinic or professional practitioner. Vitals Scan is not intended or provided as a personal or medical diagnosis, advice or treatment, medical service or as a medical device.`,

        `Use of Vitals Scan is subject to the PanopticAI: (a) <a target="_blank" href="https://panoptic.ai/end-user-licence-agreement/">Terms of Use</a>, (b) <a target="_blank" href="https://panoptic.ai/personal-information-collection-statement/">Personal Information Collection Statement (PICS)</a>, and (c) <a target="_blank" href="https://panoptic.ai/privacy-policy/">Privacy Policy</a>. By using Vitals Scan and providing your personal data, you accept and agree to be bound by these Terms and Conditions and the PanopticAI Terms of Use, PICS and Privacy Policy.`,

        `PanopticAI will not bear any responsibility or liability for any accuracy, reliability, and completeness of information you receive. Should you have any health-related concerns, please consult a doctor or other medical professionals.`,

        `You agree that your scan report may be shared with PanopticAI personnel for the purpose of discussing the scan report content to you. PanopticAI may provide suggestions on products available to you in response to the content of the report. However, PanopticAI disclaims any responsibility or liability with respect to any such suggestions, and the Customer acknowledges and agrees that he is solely responsible for acting on such suggestions. To the extent permitted by law, the Customer indemnifies PanopticAI for any liability arising as a result of the Customer acting on the suggestions provided by PanopticAI based on or as a result of the content of the report.`,

        `PanopticAI reserves the right to amend these Terms and Conditions without prior notice.`,

        `You agree to waive any right to claim from or to take any actions, suits or proceedings against the licensee/sub-licensee/owner of the premise, where Vitals Scan is being operated or used, for any costs, losses, damages and expenses of whatsoever nature incurred by you.`,

        `These Terms and Conditions are governed by and will be construed according to Hong Kong laws. You submit to the exclusive jurisdiction of the Hong Kong courts.`,

        `No person other than you and PanopticAI (and the premise licensee/sub-licensee/ owner) will have any right under the Contracts (Rights of Third Parties) Ordinance to enforce or enjoy the benefit of any of the provisions of these Terms and Conditions.`,

        `The English version of these Terms and Conditions prevails to the extent of any inconsistency between the English and the Chinese versions. Any Chinese versions of these Terms and Conditions are for reference only.`,
      ],
    },
    disclaimer: {
      disclaimer1: "Disclaimer: The software does not store any personal data.",
    },
    copyright: "\u00A9 2021-2024 PanopticAI Ltd. All Rights Reserved.",
    last_scan: "Last Scan",
    last_update: "Last Updated",
    option: {
      yes: "Yes",
      no: "No",
      logout_yes: "Yes",
      logout_no: "No",
    },
    user: {
      supervisor: "Supervisor",
      guest: "Guest",
    },
    company_info: {
      company_information: "Company Information",
      company_id: "Company Id",
      project_id: "Project Id",
      site_id: "Site Id",
      location: "Site Location",
      subscription_plan: "Subscription Plan",
      valid_until: "Valid Until",
    },
    user_info: {
      user_id: "User Id",
      profile_image: "Profile Image",
      personal_information: "Personal Information",
      subscription_plan: "Subscription Plan",
      valid_until: "Valid Until",
      name: "Name",
      nickname: "Nickname",
      birthday: "Birthday",
      age: "Age",
      height: "Height (cm)",
      weight: "Weight (kg)",
      gender: "Gender at birth",
      male: "Male",
      female: "Female",
      unit_system: "Unit System",
      country: "Countries",
      countryOptions: {
        hongkong: "Hong Kong, China",
        china: "Mainland China",
      },
      metric: "Metric",
      imperial: "Imperial",
      medical_history: "Medical History",
      smoking: "Do you smoke?",
      hypertension: "Do you have hypertension?",
      bp_medication: "Are you taking blood pressure medication?",
      diabetic: "Are you diabetic?",
      heart_disease: "Do you have heart disease?",
      depression: "Do you have depression?",
      department: "Department",
      type_1: "Type 1",
      type_2: "Type 2",
      plan_type: {
        free: "Free",
        pro: "Pro",
        enterprise: "Enterprise",
        team: "Team",
        unlimited: "Unlimited",
      },
    },
    password: {
      password: "Password",
      old: "Old Password",
      new: "New Password",
      confirm: "Confirm Password",
      not_match: "Password does not match",
    },
    no_data: "No Data",
    onboarding: {
      homepage: {
        step1: {
          title: "Power On Button",
          description: "Click this button to power up the system",
        },
        step2: {
          title: "Start Button",
          description:
            "When the system is powered on, you can start scanning by clicking this button",
        },
        step3: {
          title: "Navigation Bar",
          description: `Use the navigation bar to navigate between pages.`,
        },
        step4: {
          title: "Homepage",
          description:
            "This button navigate you to homepage, which is the current page, where you can scan your vital signs",
        },
        step5: {
          title: "Dashboard",
          description:
            "This button navigate you to dashboard where you can see the overview of your scanning results",
        },
        step6: {
          title: "Supervisor View",
          description:
            "This button navigate you to the supervisor view page where you can see your team members' health data",
        },
        step7: {
          title: "Event Page",
          description: "This page will be coming soon!",
        },
        step8: {
          title: "Email",
          description: "This page will be coming soon!",
        },
        step9: {
          title: "Chat",
          description: "This page will be coming soon!",
        },
        step10: {
          title: "Settings Page",
          description:
            "This button navigate you to settings page where you can edit your personal information and set your preferences for using the system",
        },
        step11: {
          title: "Help Page",
          description:
            "This button will navigate you to help page for more detailed information on how to use the system",
        },
        step12: {
          title: "Logout",
          description: "This button will navigate you to logout",
        },
        step13: {
          title: "Language",
          description: "You can change language here",
        },
      },
      dashboard: {
        step1: {
          title: "General Wellness Chart",
          description:
            "This chart shows the overall score of your generall wellness throughout a period of time",
        },
        step2: {
          title: "Time Period",
          description:
            "You can change the time period to be shown in the chart here",
        },
        step3: {
          title: "Trend Line",
          description:
            " You can choose whether to display the trend line on the chart by clicking here",
        },
        step4: {
          title: "Vital Signs",
          description:
            "The complete vital signs' latest measurements can be seen here",
        },
        step5: {
          title: "Detailed Information",
          description:
            "You can view the detailed information of each vital sign by clicking on this button",
        },
        step6: {
          title: "Share",
          description:
            "Share your vital sign measurement by clicking this button",
        },
        step7: {
          title: "General Fact",
          description:
            "Some general fact of each vital sign can be seen by cliking this button",
        },
      },
    },
    help_content: {
      disclaimer: {
        title: "Disclaimer",
        p1: "The camera-based health and wellness monitoring solution is designed for contactless measurement \
        of health vital signs such as heart rate, breathing rate, blood pressure, and O2 saturation. Video \
        footage is captured through a device's camera, but no video footage is stored.",
        p2: "WVS is to be used by individuals who do not require constant or intensive health monitoring. The device \
        is not to be used as the only method for health vital signs measurement of the individuals and is not intended \
        to replace consultations with medical experts. The measurements are not served for medical purposes. Should you \
        have any further health enquiries, please contact health care professionals or other medical experts immediately.",
      },
      setup: {
        title: "Set-up Guidelines",
        dos: {
          title: "Dos",
          p1: "For the best results, please do the following for the scanning:",
          1: "Put the camera near your eye level",
          2: "Prevent shaking the device ",
          3: "Choose a well-lit area, ensuring light (preferably indoor light) is evenly shed on your face",
          4: "Avoid backlighting ",
          5: "Keep your face well within the box and facing the camera",
          6: "Ensure suitable distance between you and the camera",
        },
        donts: {
          title: "Dont's",
          1: "Do not wear a mask",
          2: "Do not wear a hat",
          3: "Do not wear anything that will cover your face",
          4: "Do not have uneven light on you face (e.g. sunlight)",
          5: "Do not talk",
          6: "Do not make big movements",
          7: "Do not turn your head aside",
        },
        low_accuracy: {
          title: "Low Accuracy Examples",
          1: "The area is too bright or too dark",
          2: "Uneven lighting or backlighting",
          3: "The user is wearing a hat or having something cover one’s face",
          4: "The user is not facing the camera",
          5: "The distance between the user and the camera is too far",
          6: "The distance between the user and the camera is too close",
        },
      },
      operation: {
        title: "Operation",
        ground_truth: {
          title: "Measure Ground Truth with Contact-Based Device",
          p1: "The following steps should be taken before activating the WVS system so the user can compare the results from the WVS system with the results from the contact-based pulse oximeter.",
          p2: "The contact-based pulse oximeter is off until a finger is placed in it. To turn on and operate device, follow the following steps:",
          caption: `Contact-Based Device Scanning`,
          1: "Squeeze the back of the device to open it.",
          2: "Insert the index finger or ring finger into the device with the display facing up. For a left-handed person, use the left-hand index finger or ring finger. For a right-handed person, use the right-hand index finger or ring finger.",
          3: "The tip of the finger should reach the finger stop inside of the device. If the finger is not correctly positioned, repeat from Step 1.",
          4: "The device will now show your vital signs on the display.",
          p3: `For more detailed instructions on how to use the contact-based device, please refer to the manual for the device <a href="https://techdocs.masimo.com/globalassets/techdocs/pdf/lab-10169a_master.pdf">here</a>.<br>`,
        },
        scanning: {
          title: "Using The Health Scanning System",
          p1: "The following steps are done to use the WVS scanning system:",
          1: "Sit comfortably in a well-lit area before scanning. Ensure the light (preferably indoor light) is evenly shed on your face. Then place the computer on a sturdy surface to prevent shaking.",
          2: `Click on the home button (the first icon) on the Navigation Bar on the left side of the WVS system. Then click on the green '<i>Power On</i>' 
          on the top right corner to start up the camera and face scanner system. The page will then display '<i>Starting up …</i>' as shown in Figure 2 below. Click allow, if prompted to enable WVS to use your camera.`,
          caption_2: "Starting up the vital sign scanner",
          3: "When the system is ready, the system will activate your device’s camera and show it in the <i>Homepage</i> along with a green box indicating your detected face. Keep your face in the center of the green frame and facing the lens while ensuring that there is an appropriate distance between your body and the camera.",
          caption_3: "Powered up system",
          4: `Click on the green circle "<i>Start</i>" button to begin the scanning process. Before beginning the scan, you will need to follow pass the <i>Scanning 
          conditions</i> shown in the face scanner and stay still while the scanner is calibrating. Once the scanning conditions are met, a three second 
          countdown will appear in the middle of the screen to show that the scan is starting.`,
          caption_4: "Conditions for scanning",
          5: `Follow the <i>Breathing guide</i> instructions that appears in the green circle throughout the scanning process. The breathing guide will change 
          between '<i>In</i>' and '<i>Out</i>' to indicate breathing in and breathing out, as shown in Figure 12 and Figure 13. The <i>Scanning progress</i> circle 
          can be seen in the scanner too. The <i>Scanning progress</i> circle indicates the remaining time till the scanning procedure is completed. `,
          caption_5: "Scanning started",
          note_5: `Note: Users are reminded to follow the in and out guide to breath at normal resting speed, although no error in the scan will be detected if the 
          breathing in and out are not matched precisely with the visual indicator.`,
          6: `The scanning progress will complete within 30 seconds, and the vital sign results will be displayed while scanning is 
          being completed. Please wait until the scan is complete to see the results. `,
          caption_6: "Basic vital signs",
          7: `To see the detailed scanning result, go to the "<i>Dashboard</i>" by clicking on the <i>Navigation bar</i>.`,
          caption_7: "Scanning results overview",
          laptop: "Using Laptop",
          phone: "Using Phone",
          pc: "Usign PC",
        },
      },
    },
    scan_tutorial: {
      title: "Quick Guide for Scanning on Vitals",
      dos: "Dos:",
      donts: "Don'ts:",
      step1: {
        title: "Position Yourself Inside The Green Border",
        paragraph:
          "Once you click on the start button, a green human border will appear on your screen. This will guide you for a correct position and distance to make the scanning more effective. Make sure you are positioning yourself to be inside the green border.",
        do1: "Put the camera near your eye level",
        do2: "Keep your face well within the border and facing the camera",
        do3: "Ensure suitable distance between you and the camera",
        dont1: "Do not sit too far away from or too close to the device",
      },
      step2: {
        title: "Ensure Camera is Steady",
        paragraph:
          "Shaky camera may affect the reading of the scanning. Please make sure that the device is placed on a steady place and do minimum movement.",
        do1: "Place device on steady surface",
        do2: "Prevent shaking the device",
        dont1: "Do not talk",
        dont2: "Do not make big movements ",
      },
      step3: {
        title: "Whole Face Displayed on Camera",
        paragraph:
          "Make sure that your face is fully displayed on camera. Remove anything covering your face.",
        do1: "Face the camera",
        do2: "Make sure your face is not covered such as with hat, hoodie, mask, hair.",
        dont1: "Do not wear anything that cover your face (hat, hoodie, mask)",
        dont2: "Do not turn your face sideways",
      },
      step4: {
        title: "Ensure Even and Sufficient Lighthing on Your Face",
        paragraph:
          "Uneven lighting may affect the correctness of your reading. Indoor lighting is recommended to ensure correct lighting more easily",
        do1: "Choose a well-lit area, ensuring light (preferably indoor light) is evenly shed on your face",
        dont1: "Do not have uneven light on you face (e.g. sunlight)",
        dont2: "Backlighting",
      },
    },
    email_template: {
      email_content: `<html>\
        \
        <head></head>\
        \
        <body>\
          <div style='width: 650px;text-align:justify;margin: auto;'>\
            <div style='width: 560px;margin: auto;'>\
              <p style='margin-top:3em;'>Dear {username},</p>\
              <p>Thank you for using the PanopticAI Facial Health Scanning Service. Please find attached your health assessment report.</p>\
              <p>For more information, please contact us at: <br/>\
              Website: <a href="www.panoptic.ai">www.panoptic.ai</a><br/>\
              Email: <a href="mailto:hello{'@'}panoptic.ai">hello{'@'}panoptic.ai</a></p>\
              <p>Sincerely,<br />PanopticAI</p>\
              <p style='color: #999999;margin-top:4em;'>Powered by PanopticAI</p>\
            </div>\
          </div>\
        </body>\
        \
        </html>`,
      email_subject: "PanopticAI Facial Health Scanning Report",
      file_name: "FacialHealthScanningReport.pdf",
    },
    health_report: {
      title: "PERSONAL HEALTH REPORT",
      poor: "Poor",
      excellent: "Excellent",
      age: "Age",
      sex: "Sex",
      female: "F",
      male: "M",
      email: "Email",
      phone_number: "Phone Number",
      height: "Height",
      weight: "Weight",
      bmi: "BMI",
      stress_level: "Stress Level",
      general_wellness: "General Wellness",
      wellness: {
        title: "Wellness",
        generalWellness: {
          title: "General Wellness",
          definition:
            "General wellness score is calculated through a weighted score of the core vital signs and mental stress level.",
          range: "The normal general wellness score ranges from 80-100.",
          result: "Your general wellness score is {value}.",
        },
        stress: {
          title: "Stress Level",
          definition:
            "Stress level is a feeling of emotional or physical tension. High level of stress  may be caused by any event or thought that makes you feel frustrated, angry, or nervous.",
          range: "The normal resting stress rating is from 0-2.",
          result: "Your stress level is {value}.",
        },
      },
      facial: {
        title: "Facial",
        facialSkinAge: {
          title: "Facial Skin Age",
          definition:
            "Facial skin is particularly thin and the most visible part of the body. Like all skin, face skin serves as an effective barrier against the external environment.",
          range: "The younger the better!",
          result: "You are young and charming!",
        },
      },
      respiratory: {
        title: "Respiratory",
        respiratoryRate: {
          title: "Respiratory Rate",
          definition:
            "Respiratory rate is the number of breaths a person takes per minute. High level of respiratory rates may be caused by exercise, asthma, anxiety, etc. Low level of respiratory rate may be caused by side effects from medications or an underlying medical problem.",
          range:
            "Normal respiration rates for an adult person at rest range from 12 to 20 breaths per minute.",
          result: "Your respiratory rate is {value} bpm.",
        },
        spo2: {
          title: "Oxygen Saturation",
          definition:
            "Oxygen saturation is a measure of how much oxygen the red blood cells are carrying from the lungs to the rest of the body. Low level of oxygen saturation (hypoxemia) may be caused by an underlying illness that affects blood flow or breathing.",
          range: "Most people will read an SpO<sub>2</sub> of 95% or above.",
          result: "Your SpO<sub>2</sub> is {value}%.",
        },
      },
      cardiovascular: {
        title: "Cardiovascular",
        heartRate: {
          title: "Heart Rate",
          definition:
            "Heart rate is the number of beats of the heart per minute (bpm). High level of heart rate  may be caused by anxiety, heavy caffeine/alcohol consumption, etc.",
          range: "The normal resting heart rates ranges from 60-100 bpm",
          result: "Your heart rate is {value} bpm.",
        },
        hrvSdnn: {
          title: "Heart Rate Variability SDNN",
          definition:
            "HRV is a measure of the variation in time between each heartbeat. SDNN is the standard deviation of the interval between normal heartbeats. Low level of heart rate variability may be caused by stress of lack of sleep.",
          range: "The heart rate variability SDNN above 100 ms are healthy.",
          result: "Your heart rate variability SDNN is {value} ms.",
        },
        ibi: {
          title: "Heart Rate Variability IBI",
          definition:
            "HRV is a measure of the variation in time between each heartbeat. IBI is the interbeat interval. In normal heart function, each IBI value varies from beat to beat.",
          range:
            "Typical heart rate variability IBI values range from 500-1300 ms.",
          result: "Your heart rate variability ibi is {value} ms.",
        },
        bloodPressureSystolic: {
          title: "Systolic Blood Pressure",
          definition:
            "Systolic blood pressure is the peak pressure in your brachial arteries that results from the contraction of your heart muscle. Low level of blood pressure may be caused by dehydration, pregnancy, shock, endocrine disorders, etc.",
          range: "The ideal systolic blood pressure is between 90-120mmHg.",
          result: "Your systolic blood presusre is {value} mmHg.",
        },
        bloodPressureDiastolic: {
          title: "Diastolic Blood Pressure",
          definition:
            "Diastolic blood pressure is the amount of pressure in your brachial arteries when your heart muscle is relaxed. High blood pressure may be caused by unhealthy lifestyle or certain health conditions, such as diabetes and having obesity.",
          range: "The ideal diastolic blood pressure is between 60-80 mmHg.",
          result: "Your diastolic blood pressure is {value} mmHg.",
        },
        bloodPressure: {
          title: "*Blood Pressure",
        },
      },
      disclaimer: {
        title: "Disclaimer",
        p1: "The camera-based health and wellness monitoring solution is designed for contactless measurement of health vital signs such as heart rate, breathing rate, blood pressure, and O<sub>2</sub> saturation. Video footage is captured through a device's camera, but no video footage is stored.",
        p2: "The device is not to be used as the only method for health vital signs measurement of the individuals and is not intended to replace conulstations with medical experts. The measurements are not served for medical purposes. Should you have any further health enquiries, please contact health care professionals or other medical experts immediately.",
        powered_by: "Powered by ",
        powered_by_2: "",
      },
    },
    qr_code:
      "To know more about us, visit <u>https://panoptic.ai</u> or scan QR code on the right.",
    vitals_gpt: {
      disclaimer:
        "VitalsGPT is not intended for medical diagnosis and should not be used to replace medical advice.",
      error:
        "Something went wrong. Please try again.<br>If error persists, please contact PanopticAI.",
    },
    restart_scan: {
      reminder: "Reminder",
      question: "Are you sure to go back and restart everything? ",
    },
  },
  中文: {
    page: {
      dashboard: "面板",
      settings: "設置",
      help: "幫助",
      logout: "登出",
      supervisor_view: "管理員視圖",
      coming_soon: "敬請關注!<br>我們即將到來...",
    },
    guest_signin: "繼續以訪客身份登錄",
    logout_prompt: "你確定你要退出嗎？",
    instruction: {
      stay_still: "正在測量中，請保持靜止。",
      count_down1: "將在",
      count_down2: "秒後開始測量，請保持靜止。",
    },
    button: {
      mode: "顯示模式",
      fever_percentage: "臉上發燒皮膚比例",
      fever_threshold: "發燒檢查體溫",
      start: "開始",
      stop: "停止",
      shutdown: "關機",
      cancel: "取消",
      ok: "確定",
      all: "所有",
      power_on: "開機",
      full_screen: "全屏",
      save_settings: "保存設置",
      upgrade: "升級",
      change_password: "更改密碼",
      next: "下一步",
      previous: "上一步",
      finish: "完成",
      continue: "繼續",
      show_health_report: "顯示健康報告",
      clear_user_information: "清除用戶資料",
      send_to_email: "發送到郵件",
      rate_us: " 評價我們！",
      submit: "提交",
      agree_continue: "同意並繼續",
      restart_scan: "重新開始",
      yes: "是",
    },
    labels: {
      sending: "傳送報告中...",
      user_profile: "用戶資料",
      rate_us: " 您的反饋意見對我們很重要，請給我們評個分！",
      poor: "劣",
      good: "優",
      dissatisfied: "不滿意",
      satisfied: "滿意",
    },
    mode: {
      normal: "正常",
      debug: "調校",
    },
    threshold: {
      auto: "自動",
    },
    message: {
      startup: "系統已關閉。請按「開始」。",
      loading: "加載中",
      calibrating: "保持靜止，智能系統調整中 ...",
      no_face_box: "找不到人臉 ...",
      stopping: "{system_short_name} 智能系統停止中...",
      confirm_shutdown: "確定要關閉主機嗎?",
      important_message: "重要訊息",
      emmisivity:
        '{short_name} v{version} 是專為 Emmisivity <span class="text-danger font-weight-bold">{emmisivity}</span> 的 FLIR A315 而設計的。',
      auto_sys_restart: "自動重新啟動",
      prevent_overheat: "避免系統過熱，重新啟動中...",
      camera_error: "發現相機錯誤",
      system_error: "發現系統錯誤",
      camera_fatal_error: "因相機問題，系統自動停止，請聯絡 {contact}。謝謝。",
      system_fatal_error:
        "因系統發生問題，系統自動停止，請聯絡 {contact}。謝謝。",
      system_off: "系統已關閉。",
      tap_to_view: "按一下觀看。",
      condition_check_failed: "(1) 請移動進人形框內 <br/>(2) 盡量保持靜止。",
      coming_soon: "即將推出",
      breath_in: "吸",
      breath_out: "呼",
      bad_scan_message: "請重新掃描",
      weak_signal_message: "信號微弱",
      settings_updated: "設定已更新",
      password_updated: "密碼已更新",
      password_update_failed: "更改密碼失敗，請檢查您的密碼是否正確",
      fill_profile: "請填寫您的用戶資料",
      conditions: {
        lighting: "均匀光線",
        distance: "適當距離",
        centered: "面部置中",
        movement: "保持靜止",
        server_ready: "伺服器就緒",
        tooFar: "太遠",
        tooClose: "太近",
      },
      subscribe: "訂閱以觀看內容!",
      scan_quality: "掃描質量",
      email: "電郵發送至",
      feedback: "已收到您的意見，非常感謝！祝您有個美好的一天~",
      camera_preparation: "我們正在加載中，請確保您的臉朝向鏡頭！",
      loading_wait: "正在加載中，請耐心等候。",
      wait_result: "分析結果中…請耐心等候…",
    },
    terms_conditions: {
      title: "「 VITALS 智能掃描」條款及條件",
      subtitle:
        "通過使用「VITALS 智能掃描」，閣下確認已細閱、理解並同意以下全部條款及條件。",
      content: [
        `香港」指中華人民共和國香港特別行政區。`,

        `「全境智能」指 「全境智能有限公司」，以全境智能有限公司之名義營業。`,

        `VITALS 智能掃描是以攝像鏡頭為基礎的非接觸式面部評估方案，捕捉之影像及閣下所提供之數據會用作即時計算之用。`,

        `VITALS 智能掃描所提供的訊息、功能、特性和服務僅可作為資訊服務及一般健康指南，並未必涵蓋與 閣下特定情況相關的任何或所有事宜。在提供連接到 VITALS 智能掃描時，全境智能有限公司並非從事、管理、或經營診所或專業執業醫生。此掃描均不擬作或提供為個人或醫療診斷、建議或治療、醫療服務或用作醫療設備。`,

        `使用本掃描須受全境智能的 (a) <a target="_blank" href="https://panoptic.ai/%e7%b5%82%e7%ab%af%e7%94%a8%e6%88%b6%e8%a8%b1%e5%8f%af%e5%8d%94%e8%ad%b0/">使用條款及條件</a>， (b) <a target="_blank" href="https://panoptic.ai/%e5%80%8b%e4%ba%ba%e8%b3%87%e6%96%99%e6%94%b6%e9%9b%86%e8%81%b2%e6%98%8e/">個人資料收集聲明 (PICS)</a>，及 (c) <a target="_blank" href="https://panoptic.ai/%e7%a7%81%e9%9a%b1%e6%94%bf%e7%ad%96/">私隱政策</a>當中所列出之條款規範。如閣下使用本掃描及提供閣下的個人資料，即表示閣下接受並同意本條款及條件及全境智能的使用條款及條件，PICS及私隱政策約束。`,

        `全境智能有限公司對閣下所收到的信息之準確性、可靠性和完整性將不承擔任何責任或義務。如閣下有任何與健康相關之問題，請諮詢醫生或其他醫療護理專業人士。`,

        `閣下同意閣下的掃描報告可能與全境智能有限公司員工分享作為向閣下討論掃描報告內容之用。全境智能有限公司就掃描報告內容可能向閣下提供合適產品建議。全境智能有限公司對任何該等建議並不承擔任何責任或義務，顧客承認並同意其全權負責根據該等建議採取行動。在法律允許的範圍內，顧客應賠償全境智能有限公司因顧客根據報告內容提供的建議行動而產生的任何責任。`,

        `全境智能有限公司保留更改本條款及條件的權力而無須另行通知。`,

        `閣下同意放棄向正在運作或使用 「VITALS 智能掃描」處所的租用人/再授特許租用人/擁有人提出索賠或採取任何行動、起訴或法律程序的權利，以支付無論任何性質引致閣下的任何費用、損失、損害和費用。`,

        `本條款受香港法律管轄，並按此詮釋。閣下接受香港法院的專屬司法管轄權管轄。`,

        `除閣下及全境智能有限公司（以及處所租用人/再授特許租用人/擁有人）以外，並無其他人士有權按《合約（第三者權利）條例》強制執行本條款的任何條文，或享有本條款的任何條文下的利益。`,

        `本條款及條件的中文版本僅供参考之用。若英文版本和中文版本之間有任何抵觸，應以英文版本為準。`,
      ],
    },
    disclaimer: {
      disclaimer1: "免責聲明：此軟件不儲存任何個人資料。",
    },
    copyright: "\u00A9 2021-2024 全境智能有限公司 版權所有",
    last_scan: "最後一次掃描",
    last_update: "最後更新",
    option: {
      yes: "有",
      no: "沒有",
      logout_yes: "是",
      logout_no: "不是",
    },
    user: {
      supervisor: "管理員",
      guest: "訪客",
    },
    company_info: {
      company_information: "公司用戶信息",
      company_id: "公司用戶編號",
      project_id: "項目編號",
      site_id: "站點編號",
      location: "站點位置",
      subscription_plan: "訂閲計劃",
      valid_until: "有效期至",
    },
    user_info: {
      user_id: "用戶編號",
      profile_image: "頭像",
      personal_information: "個人信息",
      subscription_plan: "訂閲計劃",
      valid_until: "有效期至",
      name: "名稱",
      nickname: "綽號",
      birthday: "生日",
      age: "年齡",
      height: "身高（厘米）",
      weight: "體重（公斤）",
      gender: "出生性別",
      male: "男",
      female: "女",
      unit_system: "單位系統",
      country: "國家",
      countryOptions: {
        hongkong: "香港，中國",
        china: "中國大陸",
      },
      metric: "公制",
      imperial: "英制",
      medical_history: "病史",
      smoking: "您有吸煙嗎？",
      hypertension: "您有高血壓嗎？",
      bp_medication: "您有在服用降血壓藥物嗎？",
      diabetic: "您是糖尿病患者嗎？",
      heart_disease: "您有心臟疾病嗎？",
      depression: "您有抑鬱症嗎?",
      department: "部門",
      type_1: "類型1",
      type_2: "類型2",
      plan_type: {
        free: "免費",
        pro: "專業",
        enterprise: "企業",
        team: "團隊",
        unlimited: "無限制",
      },
    },
    password: {
      password: "密碼",
      old: "舊密碼",
      new: "新密碼",
      confirm: "確認密碼",
      not_match: "密碼不相符",
    },
    no_data: "沒有數據",
    onboarding: {
      homepage: {
        step1: {
          title: "開機按鈕",
          description: "點擊這個按鈕，已開啓系統",
        },
        step2: {
          title: "啓動按鈕",
          description: "當系統啓動後，你可以通過點擊這個按鈕開始掃描。",
        },
        step3: {
          title: "導航欄",
          description: `使用導航欄浏覽各頁。`,
        },
        step4: {
          title: "主頁",
          description:
            "這個按鈕將把你導航到主頁，也就是當前的頁面，你可以在那裏掃描你的人體體征",
        },
        step5: {
          title: "面板",
          description:
            "這個按鈕將引導你到面板，在那裏你可以查看你的掃描成績的概述。",
        },
        step6: {
          title: "管理員視圖",
          description:
            "這個按鈕將引導你到管理員視圖頁面，你可以看到你的團隊成員的健康數據。",
        },
        step7: {
          title: "活動頁面",
          description: "這個頁面很快就會推出!",
        },
        step8: {
          title: "電子郵件頁面",
          description: "這個頁面很快就會推出!",
        },
        step9: {
          title: "聊天箱",
          description: "這個頁面很快就會推出!",
        },
        step10: {
          title: "設置頁面",
          description:
            "這個按鈕將引導你進入設置頁面，在這裏你可以編輯你的個人信息並設置你的系統使用偏好。",
        },
        step11: {
          title: "幫助頁面",
          description:
            "這個按鈕將引導你進入幫助頁面，了解更多關于如何使用該系統的詳細信息",
        },
        step12: {
          title: "登出",
          description: "這個按鈕將引導你進入登出頁面6",
        },
        step13: {
          title: "語言",
          description: "你可以在這裏更換語言",
        },
      },
      dashboard: {
        step1: {
          title: "整體健康狀況圖",
          description: "這個圖表顯示了你在一段時期內的總體健康狀況。",
        },
        step2: {
          title: "時間段",
          description: "你可以在這裏改變圖表中顯示的時間段",
        },
        step3: {
          title: "趨勢線",
          description: "你可以點擊這裏選擇是否在圖表中顯示趨勢線",
        },
        step4: {
          title: "身體體征",
          description: "完整的身體體征的最新測量結果可以在這裏查看",
        },
        step5: {
          title: "詳細信息",
          description: "你可以通過點擊這個按鈕來查看每個身體體征的詳細信息",
        },
        step6: {
          title: "分享",
          description: "通過點擊這個按鈕來分享你的身體體征測量結果",
        },
        step7: {
          title: "一般資訊",
          description: "點擊這個按鈕，可以看到每個身體體征的一般資訊。",
        },
      },
    },
    help_content: {
      disclaimer: {
        title: "免責聲明",
        p1: "本系統是以攝像頭為基礎的非接觸式健康監測方案，可測量生命體徵如心率、呼吸頻率、血壓和血氧飽和度等。 捕捉影像只會作計算之用，並不會存儲任何影片片段。",
        p2: "WVS適合不需要持續或密集健康監測的個人用戶，且不能用作測量生命體徵的唯一方式或取代與醫學專家的諮詢。系統的生命體徵數據不適用於醫療目的， 如果您有任何進一步的查詢，請聯繫專業的醫療人員。",
      },
      setup: {
        title: "設置指南",
        dos: {
          title: "掃描進行時，請...",
          p1: "爲了更好的結果和使用體驗，請按以下指示完成掃描：",
          1: "將攝像頭放置於視線水平附近",
          2: "不要搖晃設備",
          3: "在有充足光源的地方進行掃描，確保光線（最好室内光源）平均照到臉上",
          4: "請避免背光",
          5: "請保持臉部在綠框中間及面向鏡頭",
          6: "請確保和鏡頭之間有合適的距離",
        },
        donts: {
          title: "掃描進行時，請不要...",
          1: "不要帶口罩",
          2: "不要帶帽子",
          3: "不要被任何東西遮擋臉部",
          4: "臉上不要有不均勻的光線 （例如陽光）",
          5: "不要談話",
          6: "身體不要大幅度移動",
          7: "不要把頭轉向旁邊",
        },
        low_accuracy: {
          title: "低準確率例子",
          1: "環境太暗或太光",
          2: "光線不均或背光",
          3: "佩戴口罩或帽子或被東西遮擋臉部",
          4: "沒有面向鏡頭",
          5: "與鏡頭的距離太遠",
          6: "與鏡頭的距離太近",
        },
      },
      operation: {
        title: "操作建議",
        ground_truth: {
          title: "請先使用接觸式設備測量數據",
          p1: "在激活 WVS 系統之前，用戶應先用接觸式脈搏血氧儀作量度，再將 WVS 系統的結果與接觸式脈搏血氧儀的結果進行比較，以得知結果的可參考性。",
          p2: "請按照以下步驟以打開和操作接觸式脈搏血氧儀：",
          1: "擠壓設備背面將其打開。",
          2: "將食指或無名指插入設備，顯示屏朝上。 慣用右手的使用者，請使用右手食指或無名指。 慣用左手的使用者，請使用左手食指或無名指。",
          3: "指尖應觸碰到設備內部的手指擋塊。 如果手指沒有正確定位，請從步驟 1 再次開始。",
          4: "該設備的顯示屏上將顯示您的生命體徵",
          p3: `有關如何使用接觸式設備的更詳細說明，請參閱<a href="https://techdocs.masimo.com/globalassets/techdocs/pdf/lab-10169a_master.pdf">此處</a>的設備手冊。`,
        },
        scanning: {
          title: "健康掃描步驟",
          p1: "使用 WVS 掃描系統的步驟如下：",
          1: "掃描開始前，先選擇光源充足的地方舒適地就座，確保光線（最好是室内光源）平均地照到臉上。然後將電腦放置在穩定的平面上，避免設備搖晃。",
          2: "點擊 WVS 系統左側導覽列上的主頁按鈕（第一個圖標），再點擊右上角的綠色「開機」按鈕以啟動相機和面部掃描儀系統。 該頁面應顯示「正在啟動... 」，如下圖所示。如提示允許 WVS 使用您的相機，請點擊允許。",
          caption_2: "生命體徵掃描儀啟動中",
          3: "系統準備就緒後將啟動攝像頭並顯示鏡頭畫面，同時顯示一個綠色框代表檢測到的面部範圍。請確保身體和鏡頭之間有合適的距離，保持臉部在綠框中間及面向鏡頭。",
          caption_3: "系統已啟動",
          4: "點擊綠色圓圈「開始」按鈕以開始掃描程序。 在開始掃描之前，您需要遵循掃描儀中顯示的條件。 滿足條件後，屏幕會出現三秒倒計時，表示掃描即將開始。",
          caption_4: "掃描啟動條件",
          5: "掃描進行時請跟隨綠色框下方綠色圓圈中出現的呼吸指示調整呼吸。 呼吸指示將在「吸氣」和「呼氣」之間切換，如下圖所示，您可從掃描進度圓圈中看到剩餘所需的時間。",
          note_5:
            "注意：如呼吸速度與指示不吻合，掃描不會檢測到錯誤，但仍建議跟隨呼吸指示以正常靜息速度呼吸。",
          caption_5: "掃描開始",
          6: "掃描會在30秒內完成，並在頁面上顯示生命體徵結果。請等待掃描完成以查看結果。",
          caption_6: "基本生命體徵",
          7: "若您想瀏覽詳細的掃描報告，請點擊導覽列上的第二個圖示前往數據板。",
          caption_7: "掃描結果概覽",
          laptop: "使用手提電腦",
          phone: "使用電話",
          pc: "使用桌上型電腦",
        },
      },
    },
    scan_tutorial: {
      title: "Vitals™ 掃描快速指南",
      dos: "請......",
      donts: "請不要......",
      step1: {
        title: "保持身體處於綠色邊框內",
        paragraph:
          "點擊開始按鈕後，屏幕上將出現綠色人形邊框。 邊框將指引您到正確的位置和距離使掃描更有效。 請確保您處於綠色邊框內。",
        do1: "將相機放置在視線水平附近",
        do2: "將臉部保持在邊框內並面向相機",
        do3: "確保您和相機之間距離適當",
        dont1: "不要坐在離設備太遠或太近的位置",
      },
      step2: {
        title: "確保相機平穩",
        paragraph:
          "相機搖晃可能會影響掃描的閱讀。請確保設備被放在平穩的位置並儘量減少移動。",
        do1: "將設備放在平穩的表面上",
        do2: "避免晃動設備",
        dont1: "不要談話",
        dont2: "身體不要大幅度移動 ",
      },
      step3: {
        title: "在相機上顯示整張臉",
        paragraph: "確保您的臉完整顯示在相機上。 拿走任何會遮擋臉部的東西。",
        do1: "面向相機",
        do2: "確保您的臉沒有被帽子、連帽衫、口罩、頭髮等遮住。",
        dont1: "不要戴任何會遮住臉的東西（帽子、連帽衫、口罩）",
        dont2: "不要側過臉",
      },
      step4: {
        title: "E確保臉上的光線均勻充足",
        paragraph:
          "不均勻的光線可能會影響閱讀的準確性。 建議使用室內照明以更容易確保照明正確。",
        do1: "選擇光線充足的區域，確保光線（最好是室內光線）均勻地照射在臉上",
        dont1: "臉上不要有不均勻的光線（例如：陽光）",
        dont2: "背光",
      },
    },
    email_template: {
      email_content: `<html>\
        \
        <head></head>\
        \
        <body>\
          <div style='width: 650px;text-align:justify;margin: auto;'>\
            <div style='width: 560px;margin: auto;'>\
              <p style='margin-top:3em;'>親愛的 {username},</p>\
              <p>感謝您使用 PanopticAI 面部健康掃描服務。 請參閱隨附的健康評估報告。</p>\
              <p>欲了解更多信息，請通過以下方式聯繫我們： <br/>\
              網站： <a href="www.panoptic.ai">www.panoptic.ai</a><br/>\
              電子郵件： <a href="mailto:hello{'@'}panoptic.ai">hello{'@'}panoptic.ai</a></p>\
              <p>PanopticAI</p>\
              <p style='color: #999999;margin-top:4em;'>Powered by PanopticAI</p>\
            </div>\
          </div>\
        </body>\
        \
        </html>`,
      email_subject: "PanopticAI 面部健康掃描報告",
      file_name: "FacialHealthScanningReport.pdf",
    },
    health_report: {
      title: "個人健康報告",
      excellent: "出色",
      poor: "不佳",
      age: "年齡",
      sex: "性別",
      email: "電郵",
      phone_number: "電話號碼",
      female: "女",
      male: "男",
      height: "身高",
      weight: "體重",
      bmi: "BMI",
      general_wellness: "綜合健康分數",
      wellness: {
        title: "整體健康",
        generalWellness: {
          title: "綜合健康",
          definition:
            "綜合健康分數是通過核心生命體徵和精神壓力水平的加權分數來計算的。",
          range: "正常的綜合健康分數為80-100",
          result: "你的綜合健康分數是{value}",
        },
        stress: {
          title: "壓力水平",
          definition:
            "壓力是一種情緒或身體緊張的感覺。 它可能來自任何讓你感到沮喪、憤怒或緊張的事件或想法。",
          range: "正常的靜息壓力等級為 0-2。",
          result: "你的壓力水平等級為{value}.",
        },
      },
      facial: {
        title: "面部",
        facialSkinAge: {
          title: "面部皮膚年齡",
          definition:
            "面部皮膚作爲抵禦外部環境的屏障是身體常年暴露在外的部位。由於面部是五官集中的區域，它也是人體最受關注的部位。",
          range: "越年輕越好！",
          result: "您一直都年輕迷人！",
        },
      },
      respiratory: {
        title: "呼吸系統",
        respiratoryRate: {
          title: "呼吸率",
          definition:
            "呼吸頻率為一個人每分鐘呼吸的次數。 呼吸頻率可能會隨著發燒、疾病、壓力和其他醫療狀況而上升。",
          range: "成年人靜息時正常呼吸頻率為每分鐘 12 至 20 次",
          result: "您的呼吸頻率為每分鐘 {value}次。",
        },
        spo2: {
          title: "血氧飽和度",
          definition:
            "血氧飽和度量度血紅細胞從肺部攜帶到身體其他部位的氧氣量。 低血氧症的常見症狀包括頭痛、心率加快、氣短等。",
          range: "大多數人的血氧飽和度為95% 或以上。",
          result: "你的血氧飽和度為 {value}%.",
        },
      },
      cardiovascular: {
        title: "心血管系統",
        heartRate: {
          title: "心率",
          definition:
            "心率是每分鐘心臟跳動的次數 (bpm)。 高心率可能是由荷爾蒙問題、焦慮、大量攝入咖啡因/酒精、貧血等引起的。",
          range: "正常的靜息心率範圍為每分鐘60-100次。",
          result: "你的心率為每分鐘{value}次",
        },
        hrvSdnn: {
          title: "心率變異度SDNN值",
          definition:
            "心率變異度是每次心跳之間時間變化的量度。 SDNN 是正常竇性搏動的 IBI 的標準偏差，以毫秒(ms) 為單位。低心率變異度可能是由睡眠不足的壓力造成的。",
          range: "100毫秒以上的心率變異度SDNN 是健康的。",
          result: "您的心率變異度 SDNN 為 {value} 毫秒。",
        },
        ibi: {
          title: "心率變異度IBI值",
          definition:
            "心率變異度是每次心跳之間時間變化的量度。 IBI 是心臟的搏動間期。 在心臟功能正常的情況下，每個 IBI 值會隨心跳改變。",
          range: "典型的心率變異度 IBI 值範圍為 500-1300 毫秒。",
          result: "您的心率變異性 IBI 為 {value} 毫秒。",
        },
        bloodPressureSystolic: {
          title: "收縮壓",
          definition:
            "收縮壓是由心肌收縮引起的肱動脈的峰值壓力。 低血壓可能是由脫水、懷孕、休克、內分泌失調等引起的。",
          range: "理想的收縮壓在 90-120mmHg 之間。",
          result: "您的收縮壓為 {value} mmHg。",
        },
        bloodPressureDiastolic: {
          title: "舒張壓",
          definition:
            "舒張壓是當您的心肌放鬆時您的肱動脈的壓力量。 高血壓可能是由不健康的生活方式或某些健康狀況引起的，例如糖尿病和肥胖。",
          range: "理想的舒張壓在 60-80 mmHg 之間。",
          result: "您的舒張壓為 {value} mmHg。",
        },
        bloodPressure: {
          title: "*血壓",
        },
      },
      disclaimer: {
        title: "免責聲明",
        p1: "以攝像頭為基礎的健康監測方案設計用於非接觸式測量健康生命體徵，例如心率、呼吸頻率、血壓和 O2 飽和度。 視頻片段將通過設備的攝像頭捕捉，但不會存儲任何視頻片段。",
        p2: "該設備不能用作個人健康生命體徵測量的唯一方法，也不能取代與醫學專家的諮詢。 而且測量不用於醫療目的。 如果您有任何進一步的健康查詢，請立即聯繫醫療保健專業人員或其他醫學專家。",
        powered_by: "由",
        powered_by_2: "提供技術支持",
      },
    },
    qr_code:
      "欲知更多關於我們的資訊，請瀏覽https://panoptic.ai 或掃描右側的二維碼。",
    vitals_gpt: {
      disclaimer: "VitalsGPT 不適用於醫療診斷，也不應用於取代醫療建議。",
      error: "發生錯誤，請重試。<br>如果錯誤繼續發生，請聯絡 PanopticAI。",
    },
    restart_scan: {
      reminder: "提示",
      question: "您確定要返回並重新開始嗎？",
    },
  },
};

module.exports = locales;
