// https://css-tricks.com/building-progress-ring-quickly/

<template>
  <div class="container">
    <svg :height="radius * 2" :width="radius * 2">
      <circle
        class="progress-background"
        stroke="white"
        fill="transparent"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
      <circle
        class="progress"
        stroke="white"
        fill="transparent"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{ strokeDashoffset }"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
      <circle
        class="breathing-circle-animation"
        v-if="startBreathing"
        :r="radius * 0.6"
        :cx="radius"
        :cy="radius"
      />
    </svg>
    <div class="text" v-if="startBreathing">
      <p class="text" :style="inOutTextStyle" v-if="startBreathing"></p>
      <!-- <p class="text">{{ text || progress+'%' }}</p> -->
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center position-absolute h-100"
    >
      <button
        class="btn rounded-circle bg-white cancel-button"
        @click="stopMeasure"
      >
        {{ t("button.cancel") }}
      </button>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  emits: ["stopMeasure"],
  props: {
    radius: Number,
    progress: Number,
    stroke: Number,
    text: String,
    startBreathing: Boolean,
  },
  data() {
    var width = window.innerWidth;
    var normalizedRadius;
    if (width > 768) {
      normalizedRadius = this.radius - this.stroke * 2;
    } else {
      normalizedRadius = this.radius - this.stroke * 2.5;
    }
    const circumference = normalizedRadius * 2 * Math.PI;

    return {
      normalizedRadius,
      circumference,
    };
  },
  methods: {
    stopMeasure() {
      this.$emit("stopMeasure");
    },
  },
  computed: {
    strokeDashoffset() {
      return this.circumference - (this.progress / 100) * this.circumference;
    },
    inOutTextStyle() {
      var _in = this.$t("message.breath_in");
      var _out = this.$t("message.breath_out");
      return `--in:"${_in}"; --out:"${_out}";`;
    },
  },
};
</script>


<style scoped>
.progress {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.container {
  bottom: 64px;
  display: flex;
  justify-content: center;
}
.text {
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
}
/* p {
    position: relative;
    left: -50%;
    transform: translate(0%, -50%);
    font-size: 1.5em;
    color: white;
} */
.progress-background {
  opacity: 0.1;
}

@keyframes textAnimation {
  0% {
    opacity: 1;
    font-size: 1.2em;
    content: var(--in);
  }
  45% {
    opacity: 1;
    font-size: 2.9em;
    content: var(--in);
  }
  46% {
    opacity: 1;
    font-size: 2.9em;
    content: var(--out);
  }
  90% {
    opacity: 1;
    font-size: 1.2em;
    content: var(--out);
  }
  91% {
    opacity: 1;
    font-size: 1.2em;
    content: var(--in);
  }
  100% {
    opacity: 1;
    font-size: 1.2em;
    content: var(--in);
  }
}
@keyframes textAnimation-mobile {
  0% {
    opacity: 1;
    font-size: 1em;
    content: var(--in);
  }
  45% {
    opacity: 1;
    font-size: 1.5em;
    content: var(--in);
  }
  46% {
    opacity: 1;
    font-size: 1.5em;
    content: var(--out);
  }
  90% {
    opacity: 1;
    font-size: 1em;
    content: var(--out);
  }
  91% {
    opacity: 1;
    font-size: 1em;
    content: var(--in);
  }
  100% {
    opacity: 1;
    font-size: 1em;
    content: var(--in);
  }
}
@keyframes circleAnimation {
  0% {
    opacity: 0.75;
    fill: #ffaa00;
    transform: scale(0.4);
  }
  50% {
    opacity: 0.9;
    fill: #00cc00;
    transform: scale(1);
  }
  100% {
    opacity: 0.75;
    fill: #ffaa00;
    transform: scale(0.4);
  }
}
@keyframes circleAnimation-mobile {
  0% {
    opacity: 0.75;
    fill: #ffaa00;
    transform: scale(0.3);
  }
  50% {
    opacity: 0.9;
    fill: #00cc00;
    transform: scale(0.9);
  }
  100% {
    opacity: 0.75;
    fill: #ffaa00;
    transform: scale(0.3);
  }
}

p::before {
  content: "";
  position: relative;
  left: -50%;
  transform: translate(0%, -50%);
  font-size: 1em;
  color: white;
  animation-name: textAnimation;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-delay: 1;
}
.breathing-circle-animation {
  opacity: 1;
  position: relative;
  left: 50%;
  top: 50%;
  animation-name: circleAnimation;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  transform-origin: center center;
}

@media (max-width: 768px) {
  .breathing-circle-animation {
    animation-name: circleAnimation-mobile;
  }
  p::before {
    animation-name: textAnimation-mobile;
  }
}

.cancel-button {
  width: 128px;
  height: 128px;
  font-size: x-large;
}
</style>