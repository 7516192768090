<template>
  <div class="face-mesh-container" ref="container">
    <canvas ref="canvas" :style="style" />
  </div>
</template>

<script>
import { FACEMESH_TESSELATION } from "@mediapipe/face_mesh";
import { drawConnectors } from "./DrawConnectors";
export default {
  name: "face-mesh-view",
  props: { result: Object },
  setup() {
    return {
      style: {},
    };
  },
  data() {
    return {
      realVideoFrameWidth: 640,
      realVideoFrameHeight: 480,
    };
  },
  watch: {
    result() {
      this.updateStyle();
      this.redraw();
    },
  },

  // methods for iPad
  methods: {
    updateStyle() {
      const video = document
        .getElementsByTagName("video")[0]
        .srcObject.getVideoTracks()[0];
      if (video) {
        var height = video.getSettings().height;
        var width = video.getSettings().width;
        var aspectRatio = width / height;
        this.aspectRatio = aspectRatio;
        this.realVideoFrameHeight = height;
        this.realVideoFrameWidth = width;
      }
      let style = {
        left: "0%",
        top: "0%",
        aspectRatio: aspectRatio,
      };
      let containerAspectRatio =
        this.$refs.container.clientWidth / this.$refs.container.clientHeight;
      if (containerAspectRatio < aspectRatio) {
        style.left = `${
          ((containerAspectRatio - aspectRatio) / containerAspectRatio / 2) *
          100
        }%`;
        style.height = "100%";
      } else {
        style.top = `${
          (((1 / containerAspectRatio - 1 / aspectRatio) *
            containerAspectRatio) /
            2) *
          100
        }%`;
        style.width = "100%";
      }
      this.style = style;
    },
    redraw() {
      let canvas = this.$refs.canvas;
      canvas.width = this.realVideoFrameHeight;
      canvas.height = canvas.width / this.aspectRatio;
      const ctx = canvas.getContext("2d");

      ctx.save();
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawConnectors(ctx, this.result.landmarks, FACEMESH_TESSELATION, {
        color: "lightgreen",
        lineWidth: 1,
      });
      ctx.restore();
    },
  },

  // methods for TV
  // methods: {
  //   updateStyle() {
  //     const video = document
  //       .getElementsByTagName("video")[0]
  //       .srcObject.getVideoTracks()[0];
  //     if (video) {
  //       var height = video.getSettings().height;
  //       var width = video.getSettings().width;
  //       var aspectRatio = width / height;
  //     }

  //     if (aspectRatio > 1) {
  //       this.realVideoFrameWidth = width / 3;
  //       this.realVideoFrameHeight = height;
  //     } else {
  //       this.realVideoFrameWidth = width;
  //       this.realVideoFrameHeight = (height * 2) / 3;
  //     }

  //     let style = {};
  //     let containerAspectRatio =
  //       this.$refs.container.clientWidth / this.$refs.container.clientHeight;
  //     if (containerAspectRatio < aspectRatio) {
  //       style.height = "100%";
  //     } else {
  //       if (aspectRatio > 1) {
  //         style.width = this.$refs.container.clientWidth / 3 + "px";
  //       } else {
  //         this.$refs.container.style.display = "block";
  //         style.position = "absolute";
  //         style.width = this.$refs.container.clientWidth + "px";
  //         style.top = this.$refs.container.clientHeight / 3 + "px";
  //         style.height = (this.$refs.container.clientHeight * 2) / 3 + "px";
  //       }
  //     }
  //     this.style = style;
  //   },
  //   redraw() {
  //     let canvas = this.$refs.canvas;
  //     canvas.width = this.realVideoFrameWidth;
  //     canvas.height = this.realVideoFrameHeight;
  //     const ctx = canvas.getContext("2d");

  //     ctx.save();
  //     ctx.clearRect(0, 0, canvas.width, canvas.height);
  //     drawConnectors(ctx, this.result.landmarks, FACEMESH_TESSELATION, {
  //       color: "lightgreen",
  //       lineWidth: 1,
  //     });
  //     ctx.restore();
  //   },
  // },
};
</script>

<style>
.face-mesh-container {
  width: calc(100% - 87px);
  height: 100vh;
  position: absolute;
  top: 0%;
  left: 87px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  overflow: hidden;
}
canvas {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@import "./face-mesh-view.css";
</style>
