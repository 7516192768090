<template>
    <data-hud class="data-hud" :title="t('health_report.cardiovascular.hrvSdnn.title')" unit="ms" :data="data"/>
</template>

<script>
import DataHud from "./data-hud.vue"
import { useI18n } from 'vue-i18n';

export default
  {
    name: 'hrv-sdnn',
    props: {
        data: Number
    },
    setup() {
    const { t, locale } = useI18n()
    return {t, locale}
    },
    components: { DataHud }
}
</script>

<style scoped>
.data-hud {
    position: relative;
}
@media (max-width: 1044px) {
    .data-hud {
        position: relative;
        margin-left: 10px;
        left: auto;
        top: auto;
    }
}
</style>


