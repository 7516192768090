import { cloudBackend } from "./user-manager-definitions";
import { User, companyUser } from "./user-definitions";
import type {
  companySettings,
  scanResults,
  userSettings,
  Health,
  VitalSignOld,
} from "./user-definitions";
import {
  generateEmptyUserSettingsObject,
  constructVitalSignsFromHealth,
} from "./user_utilities";
import Logger from "@/common/logger";

export function _generateLastScanResult(): Health {
  return {
    datetime: new Date(),
    scanParameters: {
      conditions: [true, true, true, true, false],
      invalidPixelPercentage: 0,
      signalDuration: 26.7,
      signalQuality: 80,
      snr: -3,
    },
    facialSkin: {
      facialSkinAge: 20,
    },
    vitalSigns: {
      heartRate: 68.1,
      spo2: 98.1,
      ibi: 881,
      stress: 1.1,
      respiratoryRate: 12.1,
      hrvSdnn: 51,
      hrvRmssd: 31,
      temperature: 36.5,
      bloodPressure: "120/80",
      bloodPressureSystolic: 120,
      bloodPressureDiastolic: 80,
      bloodAlcohol: "Coming Soon",
      bloodSugar: undefined,
      // vseVersion: "2.0.3",
    },
    risks: {
      cardiovascularRisks: {
        cvdRiskGeneral: 0.1,
        cvdRiskCHD: 0.1,
        cvdRiskCHF: 0.1,
        cvdRiskIC: 0.1,
        cvdRiskStroke: 0.1,
        // generalRisk: 0.1,
        // coronaryHeartDisease: 0.1,
        // congestiveHeartFailure: 0.1,
        // intermittentClaudication: 0.1,
        // stroke: 0.1,
      },
      covidRisk: { covidRisk: 0.2 },
      hreVersion: "2.0.3",
    },
    holisticHealth: {
      generalWellness: 99,
      bmi: 21.1,
      hheVersion: "2.0.5",
    },
  };
}

export class BaseUser implements User {
  /* this class is for the customer or the user who uses service provided by the company user */
  name: string | undefined;
  userId?: string | undefined;
  userSettings: userSettings | undefined;
  userType?: cloudBackend | undefined;
  lastHealth: Health | undefined;
  lastVitalSigns: VitalSignOld | undefined;
  rating: string | null = null;
  constructor() {
    this.getUserSettings();
    /* For debug */
    // this.lastHealth = _generateLastScanResult();
  }
  userSettingsValid(): boolean {
    let valid = true;
    if (this.userSettings) {
      // 18 props in userSettings
      if (Object.keys(this.userSettings).length !== 18) {
        valid = false;
      }
      // if any value is empty, null or undefined, then settings aren't valid.
      Object.values(this.userSettings).map((value) => {
        if (value === "" || value === null || value === undefined) {
          valid = false;
        }
      });
    } else {
      valid = false;
    }
    return valid;
  }
  async getUserSettings(): Promise<userSettings | undefined> {
    if (!this.userSettings) {
      if (window.sessionStorage.userSettings) {
        this.userSettings = JSON.parse(window.sessionStorage.userSettings);
      } else {
        this.userSettings = generateEmptyUserSettingsObject();
        if (this.userSettings) {
          this.userId = this.userSettings.userId;
        }
      }
    }
    if (this.userSettings && this.userSettings.name) {
      this.name = `${this.userSettings.name}`;
    }
    return this.userSettings;
  }
  async setUserSettings(newUserSettings: userSettings): Promise<any> {
    this.userSettings = newUserSettings;

    // TODO: save to local
    // Logger.log("updating user settings: ", this.userSettings);
    this.userSettings = newUserSettings;
    window.sessionStorage.userSettings = JSON.stringify(newUserSettings);

    if (this.userSettings && this.userSettings.name) {
      this.name = `${this.userSettings.name}`;
    }
    return true;
  }
  async clearUserSettings(): Promise<void> {
    this.userSettings = generateEmptyUserSettingsObject();
    window.sessionStorage.clear();
    this.lastVitalSigns = undefined;
  }
  uploadHealth(): void {
    throw new Error("Method not implemented.");
  }
  setHealth(newHealth: Health): void {
    if (newHealth && newHealth.vitalSigns) {
      let { vs, results } = constructVitalSignsFromHealth(newHealth, true); // results are newHealth without element that is undefined

      this.lastVitalSigns = vs;
      if (results) {
        this.lastHealth = results;
        if (this.lastHealth) {
          this.lastHealth.datetime = new Date();
          this.lastHealth.userId = this.userId;
          this.lastHealth.location = this.userSettings?.countries;
        }
      }
      Logger.log(
        `%cHeart rate: ${newHealth.vitalSigns.heartRate}bpm. setHealth:`,
        "color: green",
        this.lastHealth,
        this.lastVitalSigns
      );
    }
  }
  clearHealth(): void {
    this.lastHealth = undefined;
    this.lastVitalSigns = undefined;
  }
  _genLastHealth(): void {
    this.lastHealth = _generateLastScanResult();
    this.setHealth(this.lastHealth);
  }
  _genUserInfo(): void {
    let tmp: userSettings = {
      name: "DebugUser",
      email: "debug12345678@panoptic.ai",
      phoneNumber: "12345678",
      birthday: "2019-08-19",
      gender: "male",
      height: 170,
      weight: 70,
      unitSystem: false,
      countries: "Hong Kong",
      smoker: false,
      hypertension: false,
      bloodPressureMedication: false,
      diabetic: "No",
      heartDisease: false,
      depression: false,
      profileImage: "",
    };
    this.setUserSettings(tmp);
  }
}

export class BaseCompanyUser implements companyUser {
  UID: string | undefined;
  companySettings: companySettings | undefined;
  currentUser: User | undefined;
  scanResults: scanResults | undefined;
  userType?: cloudBackend | undefined;

  constructor() {
    // create an empty currentUser
    this.currentUser = new BaseUser();
  }
  companySettingsValid(): boolean {
    let valid = true;
    if (this.companySettings) {
      // 10 props in companySettings
      if (Object.keys(this.companySettings).length !== 10) {
        valid = false;
      }
      // if any value is empty, null or undefined, then settings aren't valid.
      Object.values(this.companySettings).map((value) => {
        if (value === "" || value === null || value === undefined) {
          valid = false;
        }
      });
    } else {
      valid = false;
    }
    return valid;
  }
  getCompanySettings(): Promise<companySettings | undefined> {
    throw new Error("Method not implemented.");
  }
  setCompanySettings(newCompanySettings: companySettings): Promise<any> {
    throw new Error("Method not implemented.");
  }
  loadData(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  login(containerId?: string | undefined): Promise<void> {
    throw new Error("Method not implemented.");
  }
  logout(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  ready(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  constructScanResults(): any {
    if (
      this.currentUser &&
      this.currentUser.lastHealth &&
      this.currentUser.lastHealth.vitalSigns &&
      this.UID &&
      this.companySettings
    ) {
      let currentVS = this.currentUser.lastHealth.vitalSigns;
      let vitalSigns = {
        bloodAlcohol: currentVS.bloodAlcohol,
        bloodPressure: currentVS.bloodPressure,
        bloodPressureDiastolic: currentVS.bloodPressureDiastolic,
        bloodPressureSystolic: currentVS.bloodPressureSystolic,
        bloodSugar: currentVS.bloodSugar,
        facialSkinAge: currentVS.facialSkinAge,
        heartRate: currentVS.heartRate,
        hrvIbi: currentVS.ibi,
        hrvRmssd: currentVS.hrvRmssd,
        hrvSdnn: currentVS.hrvSdnn,
        respiratoryRate: currentVS.respiratoryRate,
        spo2: currentVS.spo2,
        stress: currentVS.stress,
        stressScore: currentVS?.stressScore,
        temperature: currentVS.temperature,
        vseVersion: currentVS?.version,
      };

      // clear undefined
      let results = JSON.parse(
        JSON.stringify(vitalSigns, function (k, v) {
          if (v === undefined) {
            return null;
          } else {
            return v;
          }
        })
      );
      vitalSigns = results;

      let userSettings = this.currentUser.userSettings;
      if (userSettings) {
        let userInfo: userSettings = {
          // userId: this.currentUser.userId,
          email: userSettings.email,
          phoneNumber: userSettings.phoneNumber,
          name: userSettings.name,
          birthday: userSettings.birthday,
          gender: userSettings.gender,
          height: userSettings.height,
          weight: userSettings.weight,
          unitSystem: userSettings.unitSystem,
          countries: userSettings.countries,
          smoker: userSettings.smoker,
          hypertension: userSettings.hypertension,
          bloodPressureMedication: userSettings.bloodPressureMedication,
          diabetic: userSettings.diabetic,
          heartDisease: userSettings.heartDisease,
          depression: userSettings.depression,
        };
        // clear undefined
        results = JSON.parse(
          JSON.stringify(userInfo, function (k, v) {
            if (v === undefined) {
              return null;
            } else {
              return v;
            }
          })
        );
        userInfo = results;

        let scanResults: scanResults = {
          datetime: this.currentUser.lastHealth.datetime.toISOString(),
          UID: this.UID,
          location: this.companySettings.location,
          userInfo: userInfo,
          scanParameters: this.currentUser.lastHealth.scanParameters,
          vitalSigns: vitalSigns,
          holisticHealth: this.currentUser.lastHealth.holisticHealth,
        };
        if (this.currentUser.lastHealth.facialSkin) {
          scanResults.facialSkin = this.currentUser.lastHealth.facialSkin;
        }
        if (this.currentUser.lastHealth.risks) {
          scanResults.risks = this.currentUser.lastHealth.risks;
        }
        this.scanResults = scanResults;
      }
    }
  }
  uploadScanResults(): void {
    throw new Error("Method not implemented.");
  }
  setHealth(health: Health): void {
    throw new Error("Method not implemented.");
  }
  uploadFeedback(): Promise<void> {
    throw new Error("Method not implemented.");
  }
}
