<template>
  <div
    class="bg-white content-card my-5 justify-content-center"
    :style="pdf ? { margin: '10px 0 !important' } : {}"
  >
    <div class="d-flex flex-row px-4 py-2 opacity-border-radius bg-green">
      <h2
        class="text-white fs-3 fw-bolder px-0 align-middle m-0"
        :style="pdf ? { fontSize: '18px !important' } : {}"
      >
        {{ t("health_report." + type + ".title") }}
      </h2>
    </div>
    <div class="px-3 pt-3">
      <div v-for="vital in vitalSign" :key="vital" class="pb-3">
        <vitals :vital="vital" :type="type" :pdf="pdf" />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import Vitals from "./vitals.vue";
export default {
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  props: {
    vitalSign: Array,
    type: String,
    pdf: Boolean,
  },
  components: {
    Vitals,
  },
};
</script>

<style scoped>
.bg-green {
  background: #0ea08066;
  border-radius: 10px 10px 0px 0px;
}

.color-green {
  color: #0ea080;
}
</style>