<template>
  <!-- <BIBOCircle
    :style="{width: radius*1.5, height: radius*1.5, 'border-radius': radius}"
    :state="state"
    :radius="radius"/> -->

  <div id="get-vs-button-container">
    <button
      ref="button"
      v-if="vsStatus == 'idle'"
      id="get-vs-button"
      class="btn btn-success"
      data-bs-toggle="modal"
      data-bs-target="#userProfileInput"
      :disabled="!enable"
      @click="startMeasure"
    >
      {{ t("button.start") }}
    </button>
    <circular-progress
      v-else
      :radius="radius"
      :progress="progress"
      :stroke="10"
      :startBreathing="startBreathing"
      :text="Math.round(timeLeft).toString() + 's'"
      @stopMeasure="stopMeasure"
    />
  </div>
</template>

<script>
import { ref, inject } from "vue";
import CircularProgress from "./circular-progress.vue";
import { useI18n } from "vue-i18n";
// import BIBOCircle from './breathing-circle.vue'
import VitalsConfig from "../../plugin/plugin_configuration.js";

export default {
  name: "vital-sign-button",
  emits: ["vsMeasure:start", "vsMeasure:stop", "removeProfile"],
  props: {
    radius: Number,
    enable: Object,
    timeLeft: { type: Number, default: null },
    vsStatus: String,
  },
  setup() {
    const { t, locale } = useI18n();
    const { profileFilled, updateProfileFilled } = inject("profileFilled");

    return {
      profileFilled,
      updateProfileFilled,
      t,
      locale,
      vitalSign: ref(),
      progress: ref(0),
    };
  },
  data() {
    return {
      startBreathing: false,
    };
  },
  watch: {
    timeLeft() {
      this.progress = Math.round(
        ((this.totalTime - this.timeLeft) / this.totalTime) * 100.0
      );
    },
    vsStatus() {
      if (this.vsStatus == "measuring") {
        this.startBreathing = true;
      } else {
        this.startBreathing = false;
      }
    },
    profileFilled(val) {
      if (val) {
        this.$emit("vsMeasure:start", false);
      }
    },
  },
  created() {
    this.totalTime = VitalsConfig.Plugin.scanTime;
  },
  methods: {
    startMeasure() {
      this.$emit("removeProfile");
    },
    stopMeasure() {
      this.$emit("vsMeasure:stop", false);
    },
  },
  computed: {
    isDisable() {
      if ((this.enable !== null) & (this.enable !== undefined)) {
        return true;
      }
      return false;
    },
  },
  components: {
    CircularProgress,
    // BIBOCircle
  },
};
</script>

<style scoped>
/* #get-vs-button-container {
  position: absolute;
  left: 87px;
  width: calc(100% - 87px);
  bottom: 64px;
  display: flex;
  justify-content: center;
} */
#get-vs-button {
  opacity: 1;
  width: 128px;
  height: 128px;
  border-radius: 128px;
  font-size: x-large;
}
.btn.btn-success:disabled {
  background-color: #555555; /* Gray */
  border: none;
}
.btn-circle {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  padding: 10px 16px;
  font-size: 16px;
  text-align: center;
}

@media (max-width: 768px) {
  /* #get-vs-button-container {
        position: absolute;
        left: 0px;
        width: 100%;
        bottom: 95px;
        display: flex;
        justify-content: center;
    } */

  #get-vs-button {
    opacity: 1;
    width: 96px;
    height: 96px;
    border-radius: 96px;
  }
}
</style>
