<template>
  <div class="qr-container">
    <div>
      <p class="qr-text" v-html="t('qr_code')"></p>
    </div>
    <img src="./assets/panoptic_qrcode.png" alt="" class="qr-code" />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>

<style scoped>
.qr-code {
  width: 128px;
  height: 128px;
}
.qr-container {
  border-radius: 16px;
  background: var(
    --Background,
    linear-gradient(92deg, #007995 29.22%, #5affeb 102.03%)
  );
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  margin-bottom: 45px;
}
.qr-text {
  color: white;
  margin-right: 18px;
  margin-bottom: 0;
  font-size: 17px;
}
</style>