<template>
  <div class="d-flex flex-row gx-5 color-gray">
    <div class="col-8 d-flex flex-row">
      <div class="d-flex flex-row justify-content-end">
        <div class="d-flex flex-shrink align-self-start">
          <img
            :src="
              vital.includes('bloodPressure')
                ? require('./assets/bloodPressure.svg')
                : require(`./assets/${vital}.svg`)
            "
            alt=""
            class="pe-0 float-end icon-vital-signs"
            :style="pdf ? { width: '60px !important' } : {}"
          />
        </div>
        <div class="col mx-3">
          <div
            class="d-flex flex-row border-green fw-bolder fs-5 color-gray"
            :style="pdf ? { fontSize: '14px !important' } : {}"
          >
            {{ t("health_report." + type + "." + vital + ".title") }}
          </div>
          <div class="text-justify">
            <p class="px-0" :style="pdf ? { fontSize: '13px !important' } : {}">
              {{ t("health_report." + type + "." + vital + ".definition") }}
            </p>
          </div>

          <div class="mt-3 d-flex align-items-center">
            <div class="d-flex flex-shrink me-2">
              <img
                src="./assets/lightbulb.svg"
                alt=""
                class="icon-facts float-end"
              />
            </div>
            <div class="col-10">
              <div class="text-justify">
                <p
                  class="p-0 m-0"
                  v-html="t('health_report.' + type + '.' + vital + '.range')"
                  :style="pdf ? { fontSize: '12px !important' } : {}"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 align-self-center" v-if="vital !== 'facialSkinAge'">
      <div class="justify-content-center">
        <chart
          :tag="vital"
          :dataRange="data[vital].dataRange"
          :min="data[vital].min"
          :max="data[vital].max"
          :data="this.getData(vital).toFixed(data[vital].decimal)"
          :pdf="pdf"
        />
      </div>
      <div class="justify-content-center text-center">
        <p
          v-html="
            t('health_report.' + type + '.' + vital + '.result', {
              value: this.getData(vital).toFixed(data[vital].decimal),
            })
          "
          class="result-message"
        ></p>
      </div>
    </div>
    <div class="col align-self-center" v-else>
      <div class="justify-content-center age-number text-center">
        {{ this.getData("facialSkinAge").toFixed(0) }}
      </div>
      <div class="justify-content-center text-center">
        <p class="result-message">
          {{ t("health_report.facial.facialSkinAge.result") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { newUserManager } from "../../user/company-user-manager.ts";
import { useRouter } from "vue-router";
import { inject } from "vue";
import Chart from "./chart.vue";
export default {
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    const { profileFilled } = inject("profileFilled");
    var currentUser = newUserManager.currentUser;

    // if(!currentUser || !currentUser.currentUser.lastVitalSigns || !profileFilled) {
    if (!currentUser) {
      router.push("/");
    }

    return {
      t,
      locale,
      currentUser,
      profileFilled,
    };
  },
  props: {
    vital: String,
    type: String,
    pdf: Boolean,
  },
  components: {
    Chart,
  },
  methods: {
    getData(tag) {
      if (
        this.currentUser.currentUser.lastVitalSigns &&
        this.currentUser.currentUser.lastVitalSigns[tag]
      ) {
        return this.currentUser.currentUser.lastVitalSigns[tag];
      } else {
        return 0;
      }
    },
  },
  data() {
    return {
      data: {
        generalWellness: {
          dataRange: 100,
          min: 40,
          max: 100,
          unit: "",
          decimal: 0,
        },
        stress: {
          dataRange: 5,
          min: 0,
          max: 3,
          unit: "",
          decimal: 1,
        },
        facialSkinAge: {
          decimal: 1,
        },
        spo2: {
          dataRange: 100,
          min: 95,
          max: 100,
          unit: "%",
          decimal: 0,
        },
        heartRate: {
          dataRange: 220,
          min: 42,
          max: 83,
          unit: " bpm",
          decimal: 1,
        },
        respiratoryRate: {
          dataRange: 25,
          min: 6,
          max: 24,
          unit: " bpm",
          decimal: 0,
        },
        hrvSdnn: {
          dataRange: 200,
          min: 16.4,
          max: 100,
          unit: " ms",
          decimal: 1,
        },
        bloodPressureSystolic: {
          dataRange: 141,
          min: 70,
          max: 140,
          unit: " mmHg",
          decimal: 0,
        },
        bloodPressureDiastolic: {
          dataRange: 141,
          min: 40,
          max: 90,
          unit: " mmHg",
          decimal: 0,
        },
      },
    };
  },
};
</script>

<style scoped>
.age-number {
  color: #89c6b6;
  font-size: 45px;
}
.border-green {
  border-bottom: solid;
  border-bottom-color: #9fd9cc;
}
.color-green {
  color: #0ea080;
}
.icon-facts {
  width: 15px;
}
.icon-vital-signs {
  width: 80px;
}
p {
  margin: 0;
}
.text-justify {
  text-align: justify;
}
.result-message {
  font-size: 14px;
}
@media (min-width: 1080px) {
  .result-message {
    font-size: 16px;
  }
}
</style>