<template>
  <div class="modal-content">
    <div
      class="d-flex justify-content-center align-items-start position-absolute title w-100"
    >
      <div class="modal-title">
        <img src="./assets/vitals-logo.png" alt="" class="vitals-logo" />
      </div>
    </div>
    <div class="vitals-gpt-background" />
    <div
      class="chat-container flex-grow h-100 position-relative"
      @scroll="onScroll"
      ref="terms_conditions"
    >
      <div class="tnc-title" ref="title">{{ t("terms_conditions.title") }}</div>
      <div class="terms-conditions">
        <p>{{ t("terms_conditions.subtitle") }}</p>

        <ol>
          <li
            v-for="(terms, index) in tm('terms_conditions.content')"
            :key="index"
            v-html="terms"
          ></li>
        </ol>
      </div>
      <div class="down-arrow-container">
        <img
          src="./assets/down-arrow.svg"
          alt=""
          class="down-arrow"
          v-if="scrollDown"
        />
      </div>
    </div>
    <div
      class="button-container d-flex justify-content-center align-items-center"
    >
      <button
        class="btn end-button"
        aria-label="close"
        data-bs-dismiss="modal"
        @click="closeModal"
      >
        {{ t("button.cancel") }}
      </button>
      <button
        :class="`btn text-bubble ms-4 ${disabled ? 'disabled' : ''}`"
        @click="agreeTnc"
        :disabled="disabled"
      >
        {{ t("button.agree_continue") }}
      </button>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t, tm } = useI18n();
    return {
      t,
      tm,
    };
  },
  data() {
    return {
      disabled: true,
      scrollDown: true,
    };
  },
  props: {
    showModal: Boolean,
  },
  emits: ["updateAgreeTnc", "closeModal"],
  watch: {
    showModal() {
      const tncElement = this.$refs.terms_conditions;
      if (tncElement.scrollHeight > tncElement.offsetHeight) {
        this.disabled = true;
        this.scrollDown = true;
      } else {
        this.disabled = false;
        this.scrollDown = false;
      }
    },
  },
  methods: {
    closeModal() {
      this.$refs.title.scrollIntoView();
      this.$emit("closeModal");
      this.disabled = true;
    },
    agreeTnc() {
      this.$emit("updateAgreeTnc", true);
    },
    onScroll(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      if (this.scrollDown) {
        if (Math.round(scrollTop + offsetHeight) >= scrollHeight - 50) {
          this.disabled = false;
          this.scrollDown = false;
        } else if (Math.round(scrollTop + offsetHeight) < scrollHeight) {
          this.scrollDown = true;
        }
      } else {
        if (Math.round(scrollTop + offsetHeight) < scrollHeight - 50) {
          this.scrollDown = true;
        } else {
          this.scrollDown = false;
        }
      }
    },
  },
};
</script>

<style scoped>
ol {
  margin-bottom: 0%;
  padding-left: 28px;
}
@media (min-width: 1080px) {
  .terms-conditions {
    font-size: 13px;
  }
}
@media (min-width: 1920px) {
  .terms-conditions {
    font-size: 22px;
  }
}
</style>
