import axios from "axios";
const headers = {
  "X-Api-Key": "zURGL6ShI91D3rBNoq0Yx7jRusCCKDhPQ26zJ2ag",
  "Content-Type": "application/json",
};
const sendChat = async (chat) => {
  try {
    const res = await axios.post(
      "https://ksfsmroewd.execute-api.us-east-1.amazonaws.com/Prod/llama-2-us-east-1/",
      {
        inputs: [chat],
        parameters: {
          max_new_tokens: 256,
          top_p: 0.6,
          temperature: 0.9,
        },
      },
      { headers: headers }
    );
    return res.data.generation;
  } catch (e) {
    console.log("error");
    console.error(e);
  }
};

export { sendChat };
