<template>
    <data-hud class="data-hud" :title="title" unit="mmHg" :data="data"/>
</template>

<script>
import DataHud from "./data-hud.vue"
import { useI18n } from 'vue-i18n';

export default
  {
    name: 'blood-pressure',
    props: {
        data: String
    },
    setup() {
    const { t, locale } = useI18n()
    return {t, locale}
    },
    components: { DataHud },
    computed: {
        title() {
            return this.t('health_report.cardiovascular.bloodPressure.title');
        }
    }
}
</script>

<style scoped>
.data-hud {
    position: relative;
}
@media (max-width: 1044px) {
    .data-hud {
        position: relative;
        right: auto;
        top: auto;
    }
}
</style>


