import "bootstrap/dist/css/bootstrap.min.css";
import { createApp } from "vue";
import Main from "./main.vue";
import HomePage from "./views/home/home-page.vue";
import Settings from "./views/settings/company-settings.vue";
import HelpPage from "./views/help/help-page.vue";
import EmailPage from "./views/email/email-page.vue";
import ProfileInput from "./views/login/company-profile-input.vue";
import { createI18n } from "vue-i18n";
import locales from "./locales.js";
import { createRouter, createWebHashHistory } from "vue-router";
const dragscrollNext = require("vue-dragscroll");
import DeveloperPage from "./views/developer-page/developer-page.vue";
import Login from "./views/login/aws-login.vue";

/* AWS config */
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

let routes = [
  { path: "/", component: Login },
  { path: "/home", component: HomePage },
  { path: "/setting", component: Settings },
  { path: "/help", component: HelpPage },
  { path: "/input-profile", component: ProfileInput },
  { path: "/developer-page", component: DeveloperPage },
  { path: "/email", component: EmailPage },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const i18n = createI18n({
  legacy: false,
  locale: "En", // set locale
  messages: locales, // set locale messages
  warnHtmlInMessage: "off",
});

const app = createApp(Main);
app.use(i18n);
app.use(router);
app.directive("dragscroll", dragscrollNext);
app.mount("#app");
