<template>
  <div class="container">
    <!-- <p v-html="$t('message.condition_check_failed')" class="message"></p> -->
    <div class="condition-list">
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.lighting" />
        {{ t("message.conditions.lighting") }}
      </p>
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.distance" />
        {{
          conditions.distance
            ? t("message.conditions.distance")
            : t("message.conditions." + conditions.distanceCondition)
        }}
      </p>
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.centered" />
        {{ t("message.conditions.centered") }}
      </p>
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.movement" />
        {{ t("message.conditions.movement") }}
      </p>
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.serverReady" />
        {{ t("message.conditions.server_ready") }}
      </p>
      <!-- <p class="condition">
                <condition-icon class="icon" :fulfilled="conditions['face direction']" />
                Face direction
            </p> -->
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import ConditionIcon from "./condition-icon.vue";

export default {
  name: "conditions-hud",
  props: ["conditions"],
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  computed: {
    isOK() {
      return Object.values(this.conditions).reduce(
        (previousValue, currentValue) => {
          return previousValue && currentValue;
        },
        true
      );
    },
  },
  components: {
    ConditionIcon,
  },
};
</script>

<style scoped>
.container {
  width: 25%;
  min-width: 200px;
  text-align: center;
  display: inline-block;

  background: rgba(29, 27, 27, 0.7);
  backdrop-filter: blur(100px);
  border-radius: 10px;

  position: absolute;
  right: 5%;
  top: 25%;
}
.message {
  width: 80%;
  margin-top: 1em;
  margin-left: 0.5em;
  margin-right: 1em;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #f80fff;
}
.condition {
  text-align: left;
  color: #bbbbbb;
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 0;
}
.condition-list {
  margin-bottom: 2em;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 2em;
}
.icon {
  margin-left: 0em;
  margin-right: 0.5em;
}

@media (max-width: 1080px) {
  .condition {
    font-size: 1.5em;
  }
}
@media (max-width: 1044px) {
  .condition-list {
    margin: 1em 0em;
  }
  .icon {
    transform: scale(0.8);
    margin-right: 0.2em;
  }
}
@media (max-width: 768px) {
  .condition {
    font-size: 0.8em;
  }
  .container {
    top: auto;
    bottom: 15%;
    min-width: fit-content;
  }
}
</style>
