<template>
  <div class="d-flex flex-column align-items-end">
    <button
      v-for="(prompt, index) in prompts"
      :key="index"
      class="options animation"
      :style="{ animationDelay: 0.5 + index * 0.12 + 's' }"
      @click="() => chooseQuestion(prompt)"
    >
      {{ prompt }}
    </button>
  </div>
</template>

<script>
export default {
  setup() {},
  emits: ["chooseQuestion"],
  props: {
    prompts: Array,
  },
  methods: {
    chooseQuestion(prompt) {
      this.$emit("chooseQuestion", prompt);
    },
  },
};
</script>

<style scoped>
.options {
  border-radius: 40px;
  border: 3px solid #1aa0ae;
  padding: 20px 25px;
  color: #1ba2af;
  font-size: 18px;
  font-weight: 500;
  max-width: 500px;
  text-align: end;
  margin-bottom: 15px;
  opacity: 0%;
  background: white;
}
.animation {
  animation: bubble-in 1s ease 1 forwards;
}
@keyframes bubble-in {
  0% {
    transform: translateY(20px);
  }
  100% {
    opacity: 100%;
  }
}
@media (min-width: 1080px) {
  .options {
    padding: 20px 25px;
  }
}

@media (min-width: 1920px) {
  .options {
    font-size: 22px;
  }
}
</style>