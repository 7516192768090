<template>
  <div v-if="currentUser && currentUser.currentUser.lastVitalSigns">
    <loading-screen v-if="sendingEmail" :text="t('labels.sending')" />
    <div class="modal fade" id="vitalsGptChat" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <vitals-gpt
          :showVitalsGpt="showVitalsGpt"
          @updateShowVitalsGpt="updateShowVitalsGpt"
        />
      </div>
    </div>
    <div class="modal fade" id="backPrompt" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <back-prompt :showVitalsGpt="showVitalsGpt" @newScan="newScan" />
      </div>
    </div>
    <div class="content">
      <!-- <div class="content" v-if="currentUser && currentUser.currentUser.lastVitalSigns && profileFilled"> -->
      <div class="container-full font-sarabun">
        <!-- <div class="row mb-4 justify-content-end"> -->
        <div class="d-flex flex-row justify-content-between">
          <!-- <feedback class="col-6" /> -->
          <!-- <button
              class="btn bg-green font-sarabun"
              type="button"
              @click="sendToEmail"
              :disabled="sendingEmail"
            >
              {{ t("button.send_to_email") }}
            </button> -->
          <button
            class="btn bg-green color-white mb-4 restart-button"
            data-bs-toggle="modal"
            data-bs-target="#backPrompt"
          >
            &lt; {{ t("button.restart_scan") }}
          </button>
          <feedback />
        </div>
        <!-- <div class="row justify-content-end mb-4">
            <div class="col-2">
                <img id="logo" src="./assets/mannings-logo.svg" />
            </div>
        </div> -->
        <div class="mt-2 mb-4">
          <h1 class="fw-bolder color-green">
            {{ t("health_report.title") }}
          </h1>
        </div>
        <div
          class="d-flex flex-row bg-light py-3 px-1 content-card fw-bold px-4"
        >
          <div class="col-7">
            <div
              class="d-flex flex-row justify-content-start color-green fw-bolder fs-2 text-center px-0"
            >
              {{ userName }}
            </div>
            <!-- <div
              class="d-flex flex-row justify-content-start color-gray fs-5 text-center px-0"
            >
              {{ t("health_report.email") }}:
              {{
                currentUser ? currentUser.currentUser.userSettings.email : ""
              }}
            </div> -->
            <div class="d-flex flex-row fs-5">
              <div class="col-4 color-gray px-0">
                <div class="">{{ t("health_report.age") }}: {{ age }}</div>
                <div class="">
                  {{ t("health_report.sex") }}:
                  {{
                    currentUser !== undefined
                      ? t(
                          "health_report." +
                            currentUser.currentUser.userSettings.gender
                        )
                      : ""
                  }}
                </div>
                <div class="">
                  {{ t("health_report.bmi") }}:
                  {{
                    currentUser.currentUser.lastVitalSigns
                      ? currentUser.currentUser.lastVitalSigns.bmi.toFixed(1)
                      : ""
                  }}
                </div>
              </div>
              <div class="col-8 color-gray my-auto">
                <div class="d-flex flex-row">
                  <div class="me-2">
                    <img
                      src="./assets/location.svg"
                      alt="location"
                      class="icon p-0"
                    />
                  </div>
                  <div class="text-break">
                    {{ currentUser.companySettings.location }}
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div class="me-2">
                    <img
                      src="./assets/calendar.svg"
                      alt="date"
                      class="icon p-0"
                    />
                  </div>
                  <div class="text-break">
                    {{ lastScanTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div
              class="d-flex flex-row color-gray align-items-center h-100"
              :style="{ gap: '10px' }"
            >
              <div class="col-6 h-100">
                <div class="general-score">
                  <div class="text-center">
                    {{ t("health_report.wellness.stress.title") }}
                  </div>
                  <div
                    class="d-flex flex-row justify-content-center mt-2 mb-3 fs-3 color-green flex-wrap"
                    :style="{ gap: '5px' }"
                  >
                    <img
                      src="./assets/stress-top.svg"
                      alt="covid"
                      class="ps-0 icon-profile-vitals"
                    />
                    <div>
                      {{ this.getData("stress").toFixed(1) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 h-100">
                <div class="general-score">
                  <div class="text-center">
                    {{ t("health_report.general_wellness") }}
                  </div>
                  <div
                    class="d-flex flex-row flex-wrap justify-content-center mt-2 mb-3 fs-3 color-green"
                    :style="{ gap: '5px' }"
                  >
                    <img
                      src="./assets/general-wellness.svg"
                      alt="covid"
                      class="ps-0 icon-profile-vitals"
                    />
                    <div>
                      {{ this.getData("generalWellness").toFixed(1) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Wellness -->
        <vitals-type
          :vitalSign="['generalWellness', 'stress']"
          :type="'wellness'"
          :pdf="false"
        />

        <!-- Respiratory -->
        <vitals-type
          :vitalSign="['respiratoryRate', 'spo2']"
          :type="'respiratory'"
          :pdf="false"
        />

        <!-- Facial -->
        <vitals-type
          :vitalSign="['facialSkinAge']"
          :type="'facial'"
          :pdf="false"
        />

        <!-- Cardiovascular -->
        <vitals-type
          :vitalSign="[
            'heartRate',
            'hrvSdnn',
            'bloodPressureSystolic',
            'bloodPressureDiastolic',
          ]"
          :type="'cardiovascular'"
          :pdf="false"
        />

        <panoptic-qr />

        <disclaimer :pdf="false" />

        <!-- copyright message -->
        <div class="copyright text-center">{{ t("copyright") }}<br /></div>
      </div>
    </div>
    <div class="chat-button">
      <vitals-gpt-button @showVitalsGpt="updateShowVitalsGpt" />
    </div>

    <side-bar :showLogout="false" :showLocale="true" />
    <div class="pdf">
      <report-pdf ref="pdf" />
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { newUserManager } from "../../user/company-user-manager.ts";
import ReportPdf from "./report-pdf.vue";
import SideBar from "../sidebar/sidebar.vue";
import { useRouter } from "vue-router";
import html2pdf from "html2pdf.js";
import { inject } from "vue";
import Feedback from "../feedback/feedback-page-satisfactory.vue";
import Logger from "@/common/logger";
import VitalsType from "./vitals-type.vue";
import Disclaimer from "./disclaimer.vue";
import { VitalSignEngine } from "vital-sign-engine";
import LoadingScreen from "../home/loading-screen.vue";
import PanopticQr from "./panoptic-qr.vue";
import VitalsGptButton from "./vitals-gpt-button.vue";
import VitalsGpt from "./vitals-gpt.vue";
import BackPrompt from "./back-prompt.vue";
// import Copyright from '../../common/copyright.vue';

export default {
  name: "email-page",
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    const { profileFilled, updateProfileFilled } = inject("profileFilled");
    var currentUser = newUserManager.currentUser;

    // if(!currentUser || !currentUser.currentUser.lastVitalSigns || !profileFilled) {
    if (!currentUser) {
      router.push("/");
    }

    return {
      t,
      locale,
      currentUser,
      profileFilled,
      updateProfileFilled,
    };
  },
  components: {
    SideBar,
    ReportPdf,
    Feedback,
    VitalsType,
    Disclaimer,
    LoadingScreen,
    PanopticQr,
    VitalsGptButton,
    VitalsGpt,
    BackPrompt,
    // Copyright,
  },
  data() {
    return {
      sendingEmail: false,
      showVitalsGpt: false,
    };
  },
  created() {
    if (this.$root.$data.debugMode) {
      let currentUser = newUserManager.currentUser
        ? newUserManager.currentUser.currentUser
        : null;
      if (currentUser && !currentUser.lastHealth) {
        if (!currentUser.email) {
          currentUser._genUserInfo();
          Logger.log(
            "%c<email-page debug>generated user info",
            "color:magenta"
          );
        }
        if (!currentUser.lastHealth) {
          currentUser._genLastHealth();
          Logger.log(
            "%c<email-page debug>generated last health",
            "color:magenta"
          );
        }
        Logger.log("%cgenerated currentUser: ", "color:magenta", currentUser);
      }
    } else {
      if (
        !this.currentUser ||
        !this.currentUser.currentUser.lastVitalSigns ||
        !this.profileFilled
      ) {
        this.$router.push("/");
      }
    }
  },
  computed: {
    userName() {
      if (!this.currentUser) {
        return "";
      }
      if (this.currentUser.currentUser.name == "Guest") {
        return this.t("user.guest");
      }
      return this.currentUser.currentUser.name;
    },
    age() {
      if (!this.currentUser) {
        return "";
      }
      const age = this.currentUser.currentUser.userSettings.age;
      if (age) {
        return age;
      }
      const dateString = this.currentUser.currentUser.userSettings.birthday;
      var ageInMilliseconds = new Date() - new Date(dateString);
      return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
    },
    lastScanTime() {
      if (!this.currentUser.currentUser.lastVitalSigns) {
        return;
      }
      const d = new Date(this.currentUser.currentUser.lastVitalSigns.datetime);
      let month = String(d.getMonth() + 1);
      let day = String(d.getDate());
      const year = String(d.getFullYear());

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return `${day}/${month}/${year}`;
    },
  },
  methods: {
    async newScan() {
      await newUserManager.currentUser.currentUser.clearUserSettings();
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(false);
      this.goToHome();
    },
    goToHome() {
      this.$router.push("/home");
    },
    updateShowVitalsGpt(show) {
      this.showVitalsGpt = show;
    },
    async sendToEmail() {
      this.sendingEmail = true;
      setTimeout(() => {
        let pdfRef1 = this.$refs.pdf.$refs.report_pdf;

        if (!pdfRef1) {
          alert("Failed to send email");
          this.sendingEmail = true;
          return;
        }

        var opt = {
          filename: "myfile.pdf",
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { after: "#pdf-1" },
        };

        html2pdf()
          .set(opt)
          .from(pdfRef1)

          // for debug (downloading the pdf)
          //   .save();
          // this.sendingEmail = false;

          // for sending the report through email to the user
          .outputPdf()
          .then(async (pdf) => {
            // This logs the right base64
            let base64Report = btoa(pdf);

            let currentUser = newUserManager.currentUser.currentUser;
            if (currentUser) {
              try {
                let emailTemplate = {
                  emailContent: this.t("email_template.email_content", {
                    username: currentUser.name,
                  }),
                  emailSubject: this.t("email_template.email_subject"),
                  fileName: this.t("email_template.file_name"),
                };
                await VitalSignEngine.sendHealthReport(
                  [currentUser.userSettings.email],
                  base64Report,
                  emailTemplate,
                  "no-reply@panoptic.ai"
                );
                this.sendingEmail = false;
                alert(
                  `${this.t("message.email")} ${currentUser.name} @ {${
                    currentUser.userSettings.email
                  }}`
                );
              } catch (e) {
                console.error(e);
              }
            } else {
              alert("<Email-page> Something is Wrong.");
            }
          });
      }, 300);
    },
    getData(tag) {
      if (
        this.currentUser.currentUser.lastVitalSigns &&
        this.currentUser.currentUser.lastVitalSigns[tag]
      ) {
        return this.currentUser.currentUser.lastVitalSigns[tag];
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,400;0,700;0,800;1,300&display=swap");
.restart-button {
  font-size: 20px;
}
.bg-green {
  background: #0ea08066;
  border-radius: 10px 10px 0px 0px;
}

.border-green {
  border-bottom: solid;
  border-bottom-color: #9fd9cc;
}

.general-score {
  background-color: #eeeff3;
  border-radius: 9px;
  padding: 5px;
  height: 100%;
}

.color-green {
  color: #0ea080;
}

/deep/ .color-gray {
  color: #747579;
}

.container-full {
  padding: 50px;
  height: 100vh;
}
.email-message {
  color: red;
  margin: auto 6px;
}
.content {
  position: static;
  padding-left: 87px;
  right: 0px;
  height: 100vh;
  min-height: max-content;
  background: #e4e3e3;
  overflow-y: scroll;
  font-size: large;
}
.content-card {
  border-radius: 10px;
  box-shadow: 4px 4px 4px 0px #00000040;
}

/deep/ .font-sarabun {
  font-family: "Sarabun";
}

.icon {
  width: 22px;
  height: 30px;
}

.icon-facts {
  width: 35px;
  height: 25px;
}

.icon-profile-vitals {
  width: 50px;
  height: 50px;
}

.icon-vital-signs {
  width: 80px;
}

#logo {
  float: right;
  height: 30px;
}

#profile-img {
  margin: auto auto;
  margin-bottom: 5px;
  max-width: 60%;
}
.age-number {
  color: #89c6b6;
  font-size: xx-large;
}
.text-justify {
  text-align: justify;
}
.copyright {
  position: relative;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 0;
  background-color: #e4e3e3;
}
.panoptic-logo {
  width: 300px;
  padding: 0;
}
.chat-button {
  position: absolute;
  right: 17px;
  bottom: 15px;
}

.modal-dialog {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
}
@media (max-width: 768px) {
  .content {
    padding-left: 0px;
    margin-bottom: 50px;
  }
  .restart-button {
    font-size: 16px;
  }
}

/* pdf production */
.pdf {
  position: absolute;
  left: -10000px;
  top: auto;
  overflow: hidden;
  pointer-events: none;
}

/* pdf debug */
/* .pdf {
  position: absolute;
  overflow: hidden;
  z-index: 100;
} */
</style>
