<template>
  <div class="modal-content">
    <div class="h-100 text-center">
      <div class="vitals-gpt-background" />
      <div class="content-container">
        <div class="reminder-title">{{t('restart_scan.reminder')}}</div>
        <div class="reminder-question">
          {{t('restart_scan.question')}}
        </div>
        <div class="button-container">
          <button
            v-if="showVitalsGpt"
            class="btn btn-clear confirm-button button-cancel"
            data-bs-toggle="modal"
            data-bs-target="#vitalsGptChat"
          >
            {{t('button.cancel')}}
          </button>
          <button
            v-else
            class="btn btn-clear confirm-button button-cancel"
            aria-label="close"
            data-bs-dismiss="modal"
          >
            {{t('button.cancel')}}
          </button>
          <button
            class="btn btn-clear confirm-button button-yes"
            aria-label="close"
            data-bs-dismiss="modal"
            @click="newScan"
          >
            {{t('button.yes')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
export default {
  setup() {
    const {t} = useI18n();
    return {
      t
    }
  },
  props: {
    showVitalsGpt: Boolean,
  },
  emits: ["newScan"],
  methods: {
    newScan() {
      this.$emit("newScan");
    },
  },
};
</script>

<style scoped>
.button-cancel {
  border-color: #007995;
  color: #007995;
}
.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.button-yes {
  border-color: #d83131;
  color: #d83131;
}
.confirm-button {
  border-radius: 29.721px;
  border: 2.229px solid;
  padding: 16px 31px;
  font-size: 17px;
}
.content-container {
  padding: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 138px);
}
.modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  /* min-height: 95%; */
  max-width: 670px !important;
  max-height: 425px;
  overflow: hidden;
  border-radius: 26px;
}
.reminder-title {
  font-size: 44px;
  font-weight: 700;
}
.reminder-question {
  font-size: 22px;
  font-weight: 500;
}
.vitals-gpt-background {
  width: 100%;
  min-height: 138px;
  background-image: url(./assets/vitals-gpt-background.svg);
  background-position: bottom;
  background-size: cover;
  z-index: 0;
}
</style>