<template>
  <div class="modal-content">
    <div>
      <div
        class="d-flex justify-content-center align-items-start position-absolute title w-100"
      >
        <div class="modal-title">
          <img src="./assets/vitals-logo.png" alt="" class="vitals-logo" />
        </div>
      </div>
      <div class="vitals-gpt-background" />
    </div>
    <div
      class="chat-container flex-grow h-100 position-relative"
      ref="form_container"
      @scroll="onScroll"
    >
      <div>
        <h1 class="tnc-title">{{ t("labels.user_profile") }}</h1>
        <!-- Personal Information -->
        <form
          v-on:submit="saveSettings"
          class="input-container"
          autocomplete="off"
        >
          <div class="profile-field">
            <!-- Name -->
            <div class="col-6">
              <label for="nameInput" class="form-label">
                {{ t("user_info.name") }}
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-6">
              <input
                class="form-control end-button"
                type="text"
                id="nameInput"
                v-model="this.info.name"
                @input="checkDisabled"
              />
            </div>
          </div>
          <div class="profile-field">
            <!-- Age -->
            <div class="col-6">
              <label for="ageInput" class="form-label">
                {{ t("user_info.age") }}
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-6">
              <input
                type="number"
                class="form-control end-button"
                id="ageInput"
                v-model="this.info.age"
                @input="checkDisabled"
              />
            </div>
          </div>
          <!-- Gender -->
          <div class="profile-field">
            <div class="col-6">
              <label for="genderInput" class="form-label">
                {{ t("user_info.gender") }}
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-6">
              <select
                class="form-select end-button"
                id="genderInput"
                @change="checkDisabled"
                v-model="this.info.gender"
              >
                <option @change="checkDisabled" value=""></option>
                <option @change="checkDisabled" value="male">
                  {{ t("user_info.male") }}
                </option>
                <option @change="checkDisabled" value="female">
                  {{ t("user_info.female") }}
                </option>
              </select>
            </div>
          </div>
          <!-- Height & Weight -->
          <div class="profile-field">
            <!-- Height -->
            <div class="col-6">
              <label for="heightInput" class="form-label">
                {{ t("user_info.height") }}
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-6">
              <input
                type="number"
                class="form-control end-button"
                id="heightInput"
                v-model="this.info.height"
                @input="checkDisabled"
              />
            </div>
          </div>
          <div class="profile-field">
            <!-- Weight -->
            <div class="col-6">
              <label for="weightInput" class="form-label">
                {{ t("user_info.weight") }}
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-6">
              <input
                type="number"
                class="form-control end-button"
                id="weightInput"
                v-model="this.info.weight"
                @input="checkDisabled"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="down-arrow-container">
        <img
          src="./assets/down-arrow.svg"
          alt=""
          class="down-arrow"
          v-if="scrollDown"
        />
      </div>
    </div>
    <div
      class="button-container d-flex justify-content-center align-items-center"
    >
      <button
        class="btn end-button"
        aria-label="close"
        data-bs-dismiss="modal"
        @click="closeModal"
      >
        {{ t("button.cancel") }}
      </button>
      <button
        class="btn text-bubble ms-4"
        type="button"
        :disabled="disabled"
        data-bs-dismiss="modal"
        @click="updateProfile"
      >
        {{ t("button.continue") }}
      </button>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "user-profile-input",
  props: {
    form: Object,
  },
  emits: ["updateProfile", "closeModal"],
  setup() {
    const { t, locale } = useI18n();
    const { profileFilled, updateProfileFilled } = inject("profileFilled");
    return {
      profileFilled,
      updateProfileFilled,
      t,
      locale,
    };
  },
  mounted() {
    const formElement = this.$refs.form_container;
    if (formElement.scrollHeight > formElement.offsetHeight) {
      this.scrollDown = true;
    } else {
      this.scrollDown = false;
    }
    setTimeout(() => {
      this.loadForm();
      this.checkDisabled();
    }, 300);
  },
  methods: {
    onScroll(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      if (this.scrollDown) {
        if (Math.round(scrollTop + offsetHeight) >= scrollHeight - 50) {
          this.scrollDown = false;
        } else if (Math.round(scrollTop + offsetHeight) < scrollHeight) {
          this.scrollDown = true;
        }
      } else {
        if (Math.round(scrollTop + offsetHeight) >= scrollHeight) {
          this.scrollDown = false;
        } else {
          this.scrollDown = true;
        }
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    loadForm() {
      this.info = this.form;
      if (this.debug) {
        this.info = {
          name: "testing",
          email: "testing@panoptic.ai",
          phoneNumber: "12345678",
          birthday: "2000-08-19",
          age: 23,
          gender: "female",
          height: 160,
          weight: 53,
          unitSystem: false,
          countries: "Hong Kong",
          smoker: false,
          hypertension: false,
          bloodPressureMedication: false,
          diabetic: "No",
          heartDisease: false,
          depression: false,
        };
      }
    },
    checkDisabled() {
      let check = false;
      const required = ["name", "age", "gender", "height", "weight"];
      Object.keys(this.info).map((data) => {
        if (!required.includes(data)) {
          return;
        }
        if (this.info[data] === null || this.info[data] === "") {
          check = true;
          return;
        }
      });
      this.disabled = check;
    },
    updateProfile() {
      this.$emit("updateProfile", this.info);
      // window.localStorage.userProfile = JSON.stringify(this.form)
    },
  },
  watch: {
    form(newValue) {
      this.info = newValue;
    },
  },
  data() {
    return {
      info: {
        name: "",
        email: "",
        phoneNumber: "",
        birthday: "",
        age: "",
        gender: "",
        unitSystem: false,
        countries: "",
        height: "",
        weight: "",
        smoker: false,
        hypertension: false,
        bpMedication: false,
        diabetic: "",
        heartDisease: false,
        depression: false,
      },
      disabled: true,
      debug: this.$root.debugMode,
      scrollDown: false,
    };
  },
};
</script>

<style scoped>
.input-container {
  font-size: 25px;
  display: flex;
  flex-direction: column;
}
.form-control {
  color: black !important;
  padding: 10px 30px !important;
  font-size: 18px !important;
}
.form-select {
  color: black !important;
  padding: 10px 30px !important;
  font-size: 18px !important;
}
.profile-field {
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  align-items: center;
}
.tnc-title {
  margin-bottom: 0% !important;
}
@media (min-width: 1080px) {
  .profile-field {
    margin-top: 3%;
  }
  .form-control {
    font-size: 20px;
  }
}
</style>
