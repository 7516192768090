<template>
  <div class="locale-changer normal">
    <select v-model="$i18n.locale" id="locale-selector" width="50px">
      <option
        v-for="locale in $i18n.availableLocales"
        :key="`locale-${locale}`"
        :value="locale"
      >
        {{ locale }}
      </option>
    </select>
  </div>
</template>

<script>
//import { useI18n } from 'vue-i18n';

export default {
  name: "locale-button",
  setup() {
    //const { t, locale } = useI18n();
    //console.log(t, locale);
  },
  computed: {},
};
</script>

<style scoped>
#locale-selector {
  border-radius: 18px;
  padding: 5px;
  background-color: transparent;
  font-weight: bold;
  border: 3px solid black;
}
/* .normal {
  position: absolute;
  left: 23px;
  bottom: 10px;
  width: 40px;
}
select {
  width: 100%;
  font-size: 13px;
}

@media (max-height: 709px) {
  .normal {
    bottom: auto;
    top: 700px;
    padding-bottom: 80px;
  }
}
@media (max-width: 768px) {
  .normal {
    left: 245%;
    bottom: auto;
    top: 10px;
    padding-bottom: 0px;
  }
  select {
    margin-right: 15px;
  }
} */
</style>
