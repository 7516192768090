<template>
  <div class="feedback-container">
    <button
      class="btn bg-green font-sarabun rating-button"
      data-bs-toggle="modal"
      data-bs-target="#ratingModal"
    >
      {{ t("button.rate_us") }}
    </button>
  </div>

  <div
    class="modal fade"
    id="ratingModal"
    tabindex="-1"
    aria-labelledby="ratingModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ratingModalLabel">
            {{ t("labels.rate_us") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <div class="rate-container">
            <img
              class="rating-icon"
              src="./assets/Very-Dissatisfied.svg"
              v-on:click="updateRating(1)"
              data-bs-dismiss="modal"
            />
            <img
              class="rating-icon"
              src="./assets/Dissatisfied.svg"
              v-on:click="updateRating(2)"
              data-bs-dismiss="modal"
            />
            <img
              class="rating-icon"
              src="./assets/Neutral.svg"
              v-on:click="updateRating(3)"
              data-bs-dismiss="modal"
            />
            <img
              class="rating-icon"
              src="./assets/Satisfied.svg"
              v-on:click="updateRating(4)"
              data-bs-dismiss="modal"
            />
            <img
              class="rating-icon"
              src="./assets/Very-Satisfied.svg"
              v-on:click="updateRating(5)"
              data-bs-dismiss="modal"
            />
          </div>
          <div class="row">
            <div class="col-4" style="text-align: left">
              {{ t("labels.dissatisfied") }}
            </div>
            <div class="col-4"></div>
            <div class="col-4" style="text-align: right">
              {{ t("labels.satisfied") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { newUserManager } from "@/user/company-user-manager";
import { useI18n } from "vue-i18n";
export default {
  name: "feedback-page",
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  data() {
    return {
      rating: 5,
    };
  },
  methods: {
    updateRating(newRating) {
      this.rating = newRating;
      let currentUser = newUserManager.currentUser
        ? newUserManager.currentUser.currentUser
        : null;
      if (currentUser && newUserManager.currentUser) {
        currentUser.rating = this.rating;
        newUserManager.currentUser.uploadFeedback();
        // console.log("%csimulate upload rating: ", "color: magenta", this.rating)
        alert(this.t("message.feedback"));
      }
    },
  },
};
</script>

<style scoped>
.feedback-container {
  width: fit-content;
  margin: 0px 0px 0px 5px;
}
.bg-green {
  background: #0ea08066;
  border-radius: 10px 10px 0px 0px;
}
.font-sarabun {
  font-family: "Sarabun";
}
.rate-container {
  display: flex;
  margin: auto auto;
  justify-content: center;
}
.rating-icon {
  width: 60px;
  height: 60px;
  margin: 5px 14px;
}
@media (min-width: 1080px) {
  .rating-button {
    font-size: 20px;
  }
}
</style>