<template>
  <div class="text">{{ displayedText }}</div>
</template>

<script>
export default {
  setup() {},
  props: {
    timeLeft: Number,
  },
  data() {
    return {
      texts: {
        1: "Welcome! Lets see how your body's doing today.",
        3: "Relax, stay still, remain inside the camera frame. Breathe in and out as instructed.",
        7: "As your heart beats, it pumps waves of blood through your body.",
        11: "This changes the color of your skin very slightly - not enough that you'd notice, but enough that the camera can pick it up.",
        16: "We use advanced computer algorithm to spot these almost invisible color changes.",
        21: "Which are used to calculate your vital signs like heart rate, blood pressure, stress level and more!",
        26: "Almost there...",
        30: "Well done! Scanning completed. Hope to see you again soon!",
      },
      displayedText: "",
    };
  },
  watch: {
    timeLeft() {
      const seconds = 30 - Number(this.timeLeft.toFixed(0));
      if (Object.keys(this.texts).includes(String(seconds))) {
        this.displayedText = this.texts[seconds];
      }
    },
  },
};
</script>

<style scoped>
.text {
  color: #fff;
  text-shadow: 1px 1px #000;
}
</style>
