<template>
  <div class="content">
    <div class="container-full" ref="top">
      <div class="row mb-4 title" ref="title">
        PanopticAI - {{ t("page.help") }}
      </div>
      <div class="row">
        <div class="col-md-2 col-sm-12 mt-md-5 mt-2 content-list">
          <div class="sticky-top">
            <!-- Disclaimer -->
            <button
              class="btn btn-small btn-clear text-start fs-5 d-grid"
              v-bind:class="{ active: activeSection === 'disclaimer' }"
              @click="
                changeSection('disclaimer');
                scrollToTop();
              "
            >
              {{ t("help_content.disclaimer.title") }}
            </button>

            <!-- Set-up Guide -->
            <button
              class="btn btn-small btn-clear text-start fs-5 d-grid"
              v-bind:class="{ active: activeSection === 'guidelines' }"
              @click="
                changeSection('guidelines');
                scrollToTop();
              "
            >
              {{ t("help_content.setup.title") }}
            </button>
            <button
              class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid"
              v-if="activeSection === 'guidelines'"
              @click="scrollTo('#dos')"
            >
              {{ t("help_content.setup.dos.title") }}
            </button>
            <button
              class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid"
              v-if="activeSection === 'guidelines'"
              @click="scrollTo('#donts')"
            >
              {{ t("help_content.setup.donts.title") }}
            </button>
            <button
              class="btn btn-small btn-clear text-start ms-4 fs-6 d-grid"
              v-if="activeSection === 'guidelines'"
              @click="scrollTo('#low-accuracy')"
            >
              {{ t("help_content.setup.low_accuracy.title") }}
            </button>
          </div>
        </div>
        <div class="col mt-5 mx-1 mx-md-5 help-content" ref="text">
          <disclaimer v-if="activeSection === 'disclaimer'" />
          <setup-guidelines v-if="activeSection === 'guidelines'" />
        </div>
      </div>
      <div class="copyright text-center mb-3">
        <br /><br />{{ t("copyright") }}
      </div>
    </div>
  </div>
  <side-bar />
</template>

<script>
import SideBar from "../sidebar/sidebar.vue";
import Disclaimer from "./disclaimer.vue";
import SetupGuidelines from "./setup-guidelines.vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { newUserManager } from "../../user/company-user-manager.ts";

export default {
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();

    var currentUser = newUserManager.currentUser;
    if (!currentUser) {
      router.push("/");
    }

    return { t, locale };
  },
  components: {
    SideBar,
    Disclaimer,
    SetupGuidelines,
  },
  data() {
    return {
      activeSection: "disclaimer",
    };
  },
  methods: {
    changeSection(section) {
      this.activeSection = section;
    },
    scrollTo(section) {
      this.$refs.text.querySelector(section).scrollIntoView();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.content {
  position: static;
  padding-left: 87px;
  right: 0px;
  height: 100vh;
  min-height: max-content;
  background: #e4e3e3;
}

.container-full {
  padding-left: 41px;
  padding-top: 31px;
  padding-right: 41px;
  height: 100vh;
  overflow-y: scroll;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}
.copyright {
  position: relative;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
}

button > .active {
  font-weight: bold;
  background: #f5b33b;
}

.help-content {
  border-left: 1px solid;
}

@media (max-width: 768px) {
  .content {
    padding-left: 0px;
    height: calc(100vh - 87px);
  }
  .help-content {
    border-left: none;
    margin-bottom: 90px;
  }
  .copyright {
    bottom: 90px;
  }
  .content-list {
    border-bottom: 1px solid;
  }
}
</style>
