<template>
  <button
    class="chat-button"
    data-bs-toggle="modal"
    data-bs-target="#vitalsGptChat"
    @click="showVitalsGpt"
  >
    <img src="./assets/chat-icon.svg" alt="" class="button-text" />
  </button>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  emits: ["showVitalsGpt"],
  methods: {
    showVitalsGpt() {
      this.$emit("showVitalsGpt", true);
    },
  },
};
</script>

<style scoped>
.chat-button {
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(
    --Background,
    linear-gradient(92deg, #007995 29.22%, #5affeb 102.03%)
  );
  filter: drop-shadow(
    0px 7.320754528045654px 7.320754528045654px rgba(0, 0, 0, 0.25)
  );
  border-radius: 50%;
  padding: 55px;
  border: none;
}
.button-text {
  height: 70px;
}
</style>